import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import { InputSelect, Modal } from "prosperita-dumbo-react";
import { useFormik } from "formik";
import {
  getBeneficiaryFundWIDAPI,
  IBeneficiaryFundBody,
  IBodyPatchBeneficiary,
  patchBeneficiaryAPI,
  postBeneficiaryFundAPI,
} from "~/services/api/beneficiaries";

import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { IFund } from "~/types";
import { formatCPFCNPJ } from "~/services/utils";
import useFund from "~/hooks/useFund";
import { getFundsAPI } from "~/services/api/fund";
import useNotification from "~/hooks/useToast";

// const clientTypeDisplay: { [key: string]: string } = {
//   client: "Cliente",
//   internal: "Atendente",
//   area_manager: "Gerente de àrea",
//   tool_manager: "Gerente de ferramenta",
// };

const AddBeneficiaryFundModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();

  const {
    setAddBeneficiaryFundModal,
    setFundsBeneficiaryRender
  } =
    useBeneficiary();

  const { id } = useParams();

  const { setFunds, funds } = useFund();

  const { beneficiaryDetails } = useBeneficiary();

  const [sizeOptionsSelect, setSizeOptionsSelect] = useState<boolean>(false)

  const { setNotification, closeNotification } = useNotification()

  const validationSchema = Yup.object({
    bank: Yup.string().required("O banco é obrigatório"),
    agency: Yup.string()
      .matches(/^\d+$/, "A agência deve conter apenas números")
      .required("A agência é obrigatória"),
    account_number: Yup.string()
      .matches(/^\d+$/, "A conta deve conter apenas números")
      .required("O número da conta é obrigatório"),
  });
  const InitialValues = {
    funds: [],
    funds_label: []
  };
  const { setFieldValue, values, resetForm } = useFormik({
    initialValues: InitialValues,
    validationSchema,
    onSubmit: () => console.log(""),
  });

  const fetchFunds = useCallback(async () => {
    try {
      const { data } = await getFundsAPI();
      setFunds(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setFunds]);

  const handleSelectFund = useCallback(
    (fundsOptions: { label: string, value: string }[]) => {
      const values = fundsOptions
        ? fundsOptions.map((option) => option.value)
        : [];
      const label = fundsOptions
        ? fundsOptions.map((option) => option.label)
        : [];
      setFieldValue("funds", values);
      setFieldValue("funds_label", label);
    },
    [setFieldValue]

  );

  const fetchBeneficiaryFunds = useCallback(async () => {
    try {
      const { data } = await getBeneficiaryFundWIDAPI(Number(id));
      setFundsBeneficiaryRender(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [id, setFundsBeneficiaryRender]);

  const fetchSendFundsBeneficiary = useCallback(
    async (data: IBeneficiaryFundBody) => {
      try {
        await postBeneficiaryFundAPI(data);
      } catch {
        console.log("");
      }
    },
    []
  );

  const payload: IBodyPatchBeneficiary = useMemo(() => {
    return {
      raw_data: {
        status: beneficiaryDetails?.status,
        pending_funds_approvations: true,
      },
    };
  }, [beneficiaryDetails]);

  const fetchPatchBeneficiary = useCallback(
    async (id: number) => {
      try {
        await patchBeneficiaryAPI(id, payload);
      } catch (err: unknown) {
        console.error(err);
      }
    },
    [payload]
  );

  console.log(values)

  const fetchSendBeneficiaryFund = useCallback(async () => {
    setLoading(true);
    try {
      const fetchFundBeneficiary = (fundId: number) => {
        return fetchSendFundsBeneficiary({
          fund: fundId,
          beneficiary: Number(beneficiaryDetails?.id),
          raw_data: {
            status: "waiting_approval",
          },
        });
      };

      if (values.funds.length > 0) {
        const fetchPromises = values.funds.map((fundId) =>
          fetchFundBeneficiary(Number(fundId))
        );

        await Promise.all(fetchPromises);

        beneficiaryDetails &&
          fetchPatchBeneficiary(Number(beneficiaryDetails.id));

        setAddBeneficiaryFundModal(false);
        setNotification({
          status: "success",
          message: "O beneficiário foi associado ao fundo com sucesso..",
          title: "Beneficiário associado com sucesso",
          actived: true,
          zindex: "20",
          onClose: () => closeNotification()
        });
        setSizeOptionsSelect(false)
        fetchBeneficiaryFunds();
        setLoading(false);
      }
      setLoading(false);
      resetForm()
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
      setSizeOptionsSelect(false)
      setNotification({
        status: "error",
        message: "Não foi possível associar o beneficiário no momento, tente novamente mais tarde ou contate o administrador do sistema.",
        title: "Falha ao associar o beneficiário",
        actived: true,
        zindex: "20",
        onClose: () => closeNotification()
      });
      resetForm()
    }
  }, [
    fetchSendFundsBeneficiary,
    values,
    setLoading,
    fetchBeneficiaryFunds,
    setAddBeneficiaryFundModal,
    beneficiaryDetails,
    fetchPatchBeneficiary,
  ]);

  const fundsOptions = useMemo(() => {
    return funds
      ?.filter((fund) => !fund.raw_data.active === false)
      .map((fund: IFund) => ({
        value: String(fund.id),
        label: ` ${fund?.raw_data?.short_name?.trim()} - ${formatCPFCNPJ(fund?.raw_data?.document_number).trim()}`,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [funds]);

  useEffect(() => {
    fetchFunds();
  }, [fetchFunds, fetchBeneficiaryFunds, values]);

  return (
    <Modal
      radius
      label={<h3 className="text-[23px] mt-2">Associar fundos</h3>}
      open={open}
      height={sizeOptionsSelect || values.funds.length > 0 ? "520px" : "300px"}
      onClose={() => {
        onClose && onClose()
        resetForm()
        setSizeOptionsSelect(false)
      }}
      background="#f4f4f4"
      buttons={
        [
          {
            label: "Cancelar",
            kind: "ghost",
            onClick: () => {
              onClose && onClose()
              setSizeOptionsSelect(false)
              resetForm();
            }
          },
          {
            label: "Concluir",
            kind: "primary",
            onClick: () => fetchSendBeneficiaryFund(),
            disabled: values.funds.length === 0
          }
        ]
      }
    >
      <div className="h-[2px] bg-[#c6c6c6] w-[100%] mt-[30px] mb-0"></div>
      <div onClick={() => setSizeOptionsSelect(true)} className="h-[auto] m-0 p-0 bg-[red]">
        <InputSelect
          size={"medium"}
          id="funds"
          kind="fluid"
          noRadius
          label="Selecione o(s) Fundo(s)"
          name="fund"
          placeholder="Selecione um ou mais fundos"
          value={values.funds.length === 0 ? "" : values.funds[values.funds.length]}
          options={fundsOptions}
          isMulti
          onChange={(selectedOption) => handleSelectFund(selectedOption)}
        /></div>
    </Modal>
  );
};

export default AddBeneficiaryFundModal;
