import Margin from "~/components/Margin";
import { useCallback, useEffect, useMemo } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import { useFormik } from "formik";
import {
    getBeneficiariesAPI,
    getBeneficiariesBankAccount,
    getBeneficiaryAPI,
    IbeneficiaryBankAccount,
    patchBeneficiaryBankAccount,
} from "~/services/api/beneficiaries";
import { getBanks } from "~/services/api/banks";
import useBanks from "~/hooks/useBanks";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import useNotification from "~/hooks/useToast";
import { Modal } from "prosperita-dumbo-react";


type IAccount = {
    account_number: string;
    account_number_digit: string;
    agency: string;
    bank: string;
    bank_name: string;
};

type ImescleOptionsAccountPix = IAccount;


const ReproveAccountModalBeneficiary = ({
    onClose,
    open,
}: {
    open: boolean;
    onClose: () => void;
}) => {
    const { setLoading } = useLoading();
    // const { userProfile } = useUser();
    const { setBanks } = useBanks();
    const { id } = useParams();

    const {
        setBeneficiaries,
        setBeneficiaryDetails,
        setappReproveAccountBeneficiaryModal,
        accountBeneficiary,
        beneficiaryAccounts,
        setAccountBeneficiary
    } = useBeneficiary();

    const { setNotification, closeNotification } = useNotification()

    const fetchBanks = useCallback(async () => {
        try {
            const { data } = await getBanks();
            setBanks(data.results);
        } catch (err: unknown) {
            console.error(err);
        }
    }, []);

    const validationSchema = Yup.object({
        bank: Yup.string().required("O banco é obrigatório"),
        agency: Yup.string()
            .matches(/^\d+$/, "A agência deve conter apenas números")
            .required("A agência é obrigatória"),
        account_number: Yup.string()
            .matches(/^\d+$/, "A conta deve conter apenas números")
            .required("O número da conta é obrigatório"),
    });
    const InitialValues: Omit<ImescleOptionsAccountPix, "account_number_digit"> =
    {
        account_number: "",
        agency: "",
        bank: "",
        bank_name: "",
    };
    const { resetForm } = useFormik({
        initialValues: InitialValues,
        validationSchema,
        onSubmit: () => console.log(""),
    });


    const fetchBeneficiaries = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await getBeneficiariesAPI();
            setBeneficiaries(data.results);
            setLoading(false);
        } catch (err: unknown) {
            console.error(err);
            setLoading(false);
        }
    }, []);

    const fetchBeneficiary = useCallback(async () => {
        try {
            const { data } = await getBeneficiaryAPI(Number(id));
            setBeneficiaryDetails(data);
        } catch (err: unknown) {
            console.error(err);
        }
    }, [setBeneficiaries]);

    const account = beneficiaryAccounts?.find((account) => account)

    const fetchAccountBeneficiary = useCallback(async () => {
        try {
            const { data } = await getBeneficiariesBankAccount(Number(id))

            setAccountBeneficiary(data.results.sort((a, b) => b.id - a.id));
        }
        catch (err) {
        }

    }, [accountBeneficiary, beneficiaryAccounts, account, id])


    const payload = useMemo(() => {
        return {
            type: account?.type,
            agency: account?.agency,
            account_number: account?.account_number,
            account_number_digit: account?.account_number_digit,
            profile: account?.profile,
            beneficiary: account?.beneficiary,
            bank: account?.bank,
            status: "reproved",
            active: false,
        }
    }, [accountBeneficiary, account, beneficiaryAccounts])


    const fetchPatchAccountBeneficiary = useCallback(
        async () => {
            setLoading(true);
            try {
                await fetchBeneficiary();
                await fetchBeneficiaries()
                await patchBeneficiaryBankAccount(Number(account?.id), payload as IbeneficiaryBankAccount)
                fetchAccountBeneficiary()
                setappReproveAccountBeneficiaryModal(false)
                setLoading(false);
                setNotification({
                    status: "success",
                    message: "A conta bancária foi reprovada com sucesso!",
                    title: "Conta reprovada",
                    actived: true,
                    zindex: "20",
                    onClose: () => closeNotification
                });

                resetForm();
            } catch (err: unknown) {
                console.error(err);
                setLoading(false);
                setNotification({
                    status: "error",
                    message: "Não foi possível reprovar a conta bancária no momento. Por favor, tente novamente mais tarde ou entre em contato com o administrador do sistema.",
                    title: "Falha ao reprovar conta bancária",
                    actived: true,
                    zindex: "20",
                    onClose: () => closeNotification
                });

            }
        },
        [fetchBeneficiary, accountBeneficiary, account, beneficiaryAccounts, fetchAccountBeneficiary]
    );

    useEffect(() => {
        fetchBanks();
        fetchAccountBeneficiary()
    }, []);


    return (
        <Modal
            label="Reprovar Conta"
            open={open}
            onClose={() => {
                onClose();
                resetForm();
            }}
            width="550px"
            height="360px"
            buttons={
                [{
                    size: "large",
                    dimension: "75",
                    kind: "ghost",
                    label: "Cancelar",
                    onClick: () => {
                        onClose();

                    }
                },
                {
                    size: "large",
                    dimension: "75",
                    kind: "dangerPrimary",
                    label: "Sim, Reprovar",
                    onClick: async () => {
                        fetchPatchAccountBeneficiary();

                    }
                }
                ]
            }

        >
            <Margin mb={10} />
            <h3 style={{ textAlign: "center" }}>
                Deseja realmente Reprovar Conta abaixo?
            </h3>
            <Margin mb={10} />
            <Margin mb={40} />
            <div className="w-[98%] mx-auto text-center">
                <h4>
                    Banco:{" "}
                    <span style={{ fontWeight: "bold" }}>{account?.bank}</span>
                </h4>
                <h4>
                    Agencia:
                    <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {account?.agency || "Não cadastrado"}
                    </span>
                </h4>
                <h4>
                    Conta:
                    <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {account?.account_number || "Não cadastrado"}
                    </span>
                </h4>
                <Margin mb={10} />
            </div>
        </Modal>
    );
};

export default ReproveAccountModalBeneficiary;
