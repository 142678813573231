import Margin from "~/components/Margin";
import { useCallback, useEffect, useState } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import {
  getBeneficiariesAPI,
  deleteBeneficiaryAPI,
  getBeneficiariesBankAccount,
  deleteBeneficiaryBankAccount,
  IBankAccountResponse,
  getBeneficiaryFundWIDAPI,
  deleteBeneficiaryFundAPI,
  deleteBeneficiarykeyPIX,
  getBeneficiarieskeyPIX,
} from "~/services/api/beneficiaries";
import { Modal } from "prosperita-dumbo-react";
import useNotification from "~/hooks/useToast";

// const clientTypeDisplay: { [key: string]: string } = {
//   client: "Cliente",
//   internal: "Atendente",
//   area_manager: "Gerente de àrea",
//   tool_manager: "Gerente de ferramenta",
// };

const DeleteBeneficiaryModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {

  const { setLoading } = useLoading();

  const {
    beneficiaryDetails,
    setFundsBeneficiaryRender,
    fundsBeneficiaryRender,
    setBeneficiaries,
    setOpenModalDeleteBeneficiary,
    renderPixKeys,
    setRenderPixKeys,
  } = useBeneficiary();


  const [takeAccoutsBeneficiary, settakeAccoutsBeneficiary] = useState<IBankAccountResponse[]>()

  const { setNotification, closeNotification } = useNotification()

  const fetchBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiariesAPI();
      setBeneficiaries(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setBeneficiaries]);


  const fetchAccountBeneficiary = useCallback(async () => {

    try {

      const { data } = await getBeneficiariesBankAccount(Number(beneficiaryDetails?.id))

      settakeAccoutsBeneficiary(data.results)

    }
    catch (err) {
    }
  }, [beneficiaryDetails])


  const fetchFundsBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiaryFundWIDAPI(Number(beneficiaryDetails?.id));
      setFundsBeneficiaryRender(data.results.reverse());

    } catch {
      console.warn("erro");
    }
  }, [
    setFundsBeneficiaryRender,
    beneficiaryDetails,
  ]);

  const fetchPixKeyBeneficiary = useCallback(async () => {

    try {
      const { data } = await getBeneficiarieskeyPIX(Number(beneficiaryDetails?.id))

      setRenderPixKeys(data.results.sort((b, a) => b.id - a.id))

    } catch (err) {

      console.warn(err)

    }
  }, [renderPixKeys, beneficiaryDetails])


  const fetchDeleteBeneficiary = useCallback(

    async (id: number) => {

      setLoading(true);

      try {

        await deleteBeneficiaryAPI(id);

        await fetchBeneficiary();

        const fetchDeleteAccoutsBeneficiary = (id: number) => {

          return deleteBeneficiaryBankAccount(id);
        };

        const fetchDeleteFundsBeneficiary = (id: number) => {
          return deleteBeneficiaryFundAPI(id)
        }

        const fetchDeletePIXbeneficiary = (id: number) => {
          return deleteBeneficiarykeyPIX(id)
        }

        if (renderPixKeys && renderPixKeys?.length > 0) {
          const fetchPromises = renderPixKeys.map((PIX) => {
            fetchDeletePIXbeneficiary(Number(PIX.id))
          })
          await Promise.all(fetchPromises);
        }

        if (fundsBeneficiaryRender && fundsBeneficiaryRender?.length > 0) {
          const fetchPromises = fundsBeneficiaryRender.map((funds) => {
            fetchDeleteFundsBeneficiary(Number(funds.id))
          })
          await Promise.all(fetchPromises);
        }

        if (takeAccoutsBeneficiary && takeAccoutsBeneficiary?.length > 0) {
          const fetchPromises = takeAccoutsBeneficiary.map((accouts) =>
            fetchDeleteAccoutsBeneficiary(Number(accouts.id))
          );
          await Promise.all(fetchPromises);
        }

        setOpenModalDeleteBeneficiary(false);

        setLoading(false);

        setNotification({
          status: "success",
          message: "O beneficiário foi removido com sucesso.",
          title: "Removido com sucesso",
          actived: true,
          zindex: "20",
          onClose: () => closeNotification()
        });
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        setNotification({
          status: "error",
          message: "Não foi possível remover o beneficiário no momento, tente novamente mais tarde ou contate o administrador do sistema.",
          title: "Falha ao remover beneficiário",
          actived: true,
          zindex: "20",
          onClose: () => closeNotification()
        });
      }
    },
    [setLoading, beneficiaryDetails, takeAccoutsBeneficiary]
  );

  useEffect(() => {
    fetchBeneficiary();
    fetchAccountBeneficiary()
    fetchFundsBeneficiary()
    fetchPixKeyBeneficiary()
  }, [fetchFundsBeneficiary]);


  return (
    <Modal
      radius
      label={<h3 className="mt-[10px] m-0 p-0"> Remover, {beneficiaryDetails?.name}</h3>}
      open={open}
      onClose={onClose}
      buttons={
        [
          {
            label: "Não, manter como está",
            kind: "secondary",
            onClick: () => setOpenModalDeleteBeneficiary(false)
          },
          {
            label: "Sim, remover",
            kind: "dangerPrimary",
            onClick: async () =>
              beneficiaryDetails?.name &&
              fetchDeleteBeneficiary(Number(beneficiaryDetails.id))
          }
        ]
      }
    >
      <Margin mt={2} />
      <p className="w-[95%] mx-auto text-[16px] m-0 p-0">
        Deseja realmente remover o benefícios abaixo? Esta ação não poderá ser revertida
      </p>
      <Margin mt={80} />
    </Modal>
  );
};

export default DeleteBeneficiaryModal;
