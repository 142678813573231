import Margin from "~/components/Margin";
import { useCallback, useEffect, useMemo } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import { useFormik } from "formik";
import {
  getBeneficiariesAPI,
  getBeneficiaryAPI,
  IBodyBeneficiary,
  patchBeneficiaryAPI,
} from "~/services/api/beneficiaries";
import { getBanks } from "~/services/api/banks";
import useBanks from "~/hooks/useBanks";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Modal } from "prosperita-dumbo-react";
import useNotification from "~/hooks/useToast";

// const clientTypeDisplay: { [key: string]: string } = {
//   client: "Cliente",
//   internal: "Atendente",
//   area_manager: "Gerente de àrea",
//   tool_manager: "Gerente de ferramenta",
// };

type IAccount = {
  account_number: string;
  account_number_digit: string;
  agency: string;
  bank: string;
  bank_name: string;
};

type ImescleOptionsAccountPix = IAccount;

const ReproveBeneficiaryModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { setBanks } = useBanks();
  const { id } = useParams();

  const {
    setBeneficiaries,
    setBeneficiaryDetails,
    beneficiaryDetails,
    setEditModalBeneficiary,
    setOpenReproveBeneficiaryModal,
  } = useBeneficiary();

  const navigate = useNavigate();

  const { setNotification, closeNotification } = useNotification()

  const fetchBanks = useCallback(async () => {
    try {
      const { data } = await getBanks();
      setBanks(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, []);

  const validationSchema = Yup.object({
    bank: Yup.string().required("O banco é obrigatório"),
    agency: Yup.string()
      .matches(/^\d+$/, "A agência deve conter apenas números")
      .required("A agência é obrigatória"),
    account_number: Yup.string()
      .matches(/^\d+$/, "A conta deve conter apenas números")
      .required("O número da conta é obrigatório"),
  });
  const InitialValues: Omit<ImescleOptionsAccountPix, "account_number_digit"> =
  {
    account_number: "",
    agency: "",
    bank: "",
    bank_name: "",
  };
  const { resetForm } = useFormik({
    initialValues: InitialValues,
    validationSchema,
    onSubmit: () => console.log(""),
  });

  const fetchBeneficiaries = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getBeneficiariesAPI();
      setBeneficiaries(data.results);
      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, []);

  const payload: IBodyBeneficiary = useMemo(() => {
    return {
      name: beneficiaryDetails?.name || "",
      document_number: beneficiaryDetails?.document_number || "",
      status: "reproved",
      active: false,
      raw_data: {
        pending_funds_approvations:
          beneficiaryDetails?.raw_data?.pending_funds_approvations,
      },
    };
  }, [beneficiaryDetails]);

  const fetchBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiaryAPI(Number(id));
      setBeneficiaryDetails(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setBeneficiaries]);

  const fetchPatchBeneficiary = useCallback(
    async (id: number) => {
      setLoading(true);
      try {
        await patchBeneficiaryAPI(id, payload);
        navigate("/app/beneficiaries");
        setOpenReproveBeneficiaryModal(false);
        await fetchBeneficiary();
        await fetchBeneficiaries();
        setEditModalBeneficiary(false);
        setLoading(false);
        setNotification({
          status: "success",
          title: "Beneficiário Reprovado",
          message: "O beneficiário foi reprovado com sucesso.",
          actived: true,
          zindex: "20",
          onClose: closeNotification
      });
        resetForm();
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        setNotification({
          status: "error",
          title: "Erro ao Reprovar",
          message: "Não foi possível reprovar o beneficiário. Tente novamente mais tarde.",
          actived: true,
          zindex: "20",
          onClose: closeNotification
      });
      
      }
    },
    [payload, fetchBeneficiary]
  );

  useEffect(() => {
    fetchBeneficiaries();
    fetchBeneficiary();
    fetchBanks();
  }, []);

  return (
    <Modal
      label="Reprovar Beneficiário"
      open={open}
      height="400px"
      onClose={() => {
        onClose();
      }}

      buttons={[

        {
          label: "Cancelar",
          kind: "ghost",
          onClick: () => onClose()
        },
        {
          label: "Sim, Reprovar",
          kind: "dangerPrimary",
          onClick: () => fetchPatchBeneficiary(Number(beneficiaryDetails?.id))
        }
      ]}

    >
      <Margin mb={10} />
      <div className="mx-auto w-[97%] text-center">
        <h3 style={{ textAlign: "center" }}>
          Deseja realmente reprovar o beneficiário abaixo?
        </h3>
        <Margin mb={10} />
        <Margin mb={40} />
        <h4>
          Nome/Razão Social:{" "}
          <span style={{ fontWeight: "bold" }}>{beneficiaryDetails?.name}</span>
        </h4>
        <h4>
          CPF/CNPJ:
          <span style={{ fontWeight: "bold" }}>
            {" "}
            {beneficiaryDetails?.document_number || "Não cadastrado"}
          </span>
        </h4>
      </div>
    </Modal >
  );
};

export default ReproveBeneficiaryModal;
