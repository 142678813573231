import Margin from "~/components/Margin";
import { useCallback, useEffect } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import useUser from "~/hooks/useUser";
import {
  deleteBeneficiarykeyPIX,
  getBeneficiarieskeyPIX,
  getBeneficiaryAPI,
} from "~/services/api/beneficiaries";
import { useParams } from "react-router-dom";
import { Modal } from "prosperita-dumbo-react";
import useNotification from "~/hooks/useToast";

const TypeKeysLabel: { [key: string]: string } = {
  cnpj: "CNPJ",
  cpf: "CPF",
  telefone: "Celular",
  evp: "Chave Aleatoria",
  email: "Email",
};

export const DeleteModalKeyPix = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { userProfile } = useUser();
  const { id } = useParams();

  const {
    setBeneficiaryDetails,
    beneficiaryDetails,
    setaddkeyPixModal,
    pixkeyUser,
    setdeleteModalKeyPix,
    setRenderPixKeys
  } = useBeneficiary();

  const {
    setNotification,
    closeNotification } =
    useNotification()

  const fetchBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiaryAPI(Number(id));
      setBeneficiaryDetails(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [id, setBeneficiaryDetails]);

  const fetchPIXkeysBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiarieskeyPIX(Number(pixkeyUser?.beneficiary))
      setRenderPixKeys(data.results)
    } catch (err) {
      console.warn(err)
    }
  }, [pixkeyUser])

  const fetchDeleteKeyPIXbeneficiary = useCallback(
    async () => {
      setLoading(true);
      try {
        setdeleteModalKeyPix(false);
        await fetchBeneficiary();
        await deleteBeneficiarykeyPIX(Number(pixkeyUser?.id))
        fetchPIXkeysBeneficiary()
        setaddkeyPixModal(false);
        setLoading(false);
        setNotification({
          status: "success",
          message: "Chave Pix removida com sucesso.",
          title: "Sucesso",
          actived: true,
          onClose: () => closeNotification(),
          zindex: "14"
        });

      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        setNotification({
          status: "error",
          message: "Não foi possível deletar a chave Pix.",
          title: "Erro ao Remover Chave Pix",
          actived: true,
          onClose: () => closeNotification(),
          zindex: "14"
        });

      }
    },
    [fetchBeneficiary, pixkeyUser, userProfile, beneficiaryDetails]
  );

  useEffect(() => {
    fetchBeneficiary();
  }, []);

  return (
    <Modal
      label="Remover Chave Pix Cadastrada"
      open={open}
      onClose={onClose}
      buttons={
        [
          {
            label: "Cancelar",
            onClick: () => onClose && onClose()
          },
          {
            label: "Remover Chave",
            onClick: async () => {
              fetchDeleteKeyPIXbeneficiary()
            },
            size: "large",
            kind: "primary"
          }
        ]
      }

    >
      <div className="w-[98%] mx-auto text-center">
        <h3 style={{ textAlign: "center" }}>
          Deseja realmente remover a Chave PIX Abaixo?
        </h3>
        <Margin mt={20} />
        <h3>
          {" "}
          <span style={{ fontWeight: "bold" }}>Chave: </span>
          {pixkeyUser?.key}
        </h3>
        <h3>
          {" "}
          <span style={{ fontWeight: "bold" }}>Tipo: </span>
          {TypeKeysLabel[pixkeyUser?.type || "Não encontrada"]}
        </h3>
      </div>
      <Margin mb={50} />
    </Modal>
  );
};
