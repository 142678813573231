import Margin from "~/components/Margin";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import useUser from "~/hooks/useUser";
import { useFormik } from "formik";
import {
  getBeneficiarieskeyPIX,
  getBeneficiaryAPI,
  postBeneficiarykeyPIX,
} from "~/services/api/beneficiaries";
import { useParams } from "react-router-dom";
import { IkeypixType, postCheckpixAPI } from "~/services/api/checkpix";
import { Dropdown, InputFluid, Modal } from "prosperita-dumbo-react";
import { onlyNumbers } from "~/services/utils";
import useNotification from "~/hooks/useToast";
import renewIcon from "~/assets/statusicon.png"

type IBankAccountInfoPix = {
  account_number: string;
  account_number_digit: string;
  account_type: string;
  agency: string;
  bank: string;
  bank_name: string;
  document_number: string;
  key: string;
  key_type: string;
  message: string;
  name: string;
};

type InitialValues = {
  cnpj: string;
  cpf: string;
  telefone: string;
  evp: string;
  email: string;
  type: string;
  bank: string;
  key: string
};

const optionsPIX = [
  { label: "CNPJ", value: "cnpj" },
  { label: "CPF", value: "cpf" },
  { label: "Celular", value: "telefone" },
  { label: "Email", value: "email" },
  { label: "Aleatória", value: "evp" },
];

const initialValues: InitialValues = {
  cnpj: "",
  cpf: "",
  telefone: "",
  evp: "",
  email: "",
  type: "",
  bank: "",
  key: ""
};

export const AddModalPixkey = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {

  const { setLoading } = useLoading();
  const { userProfile } = useUser();
  const { id } = useParams();
  const [step, setStep] = useState<"initial" | "hasKey">("initial");
  const [stepLoading, setStepLoading] = useState(false);
  const [pagekeyPixError, setpagekeyPixError] = useState<boolean>(false);
  const [pixData, setPIXData] = useState<IBankAccountInfoPix | null>();
  const [warningFilds, setWarningFilds] = useState<boolean>(false)
  const [clearFiles, setClearFiles] = useState<boolean>(false)

  const {
    setNotification,
    closeNotification } =
    useNotification()

  const {
    setBeneficiaryDetails,
    beneficiaryDetails,
    setaddkeyPixModal,
    setRenderPixKeys,
    renderPixKeys
  } =
    useBeneficiary();

  const {
    values,
    handleChange,
    resetForm
  } = useFormik({
    initialValues,
    onSubmit: () => { },
  });

  const payload_key = useMemo(() => {
    return {
      key:
        values.type === "email" || values.type === "evp"
          ? values.key
          : onlyNumbers(values.key),
      type: values.type,
    };
  }, [values]);


  const payload = useMemo(() => {
    return {
      beneficiary: Number(beneficiaryDetails?.id),
      active: true,
      key: String(pixData?.key),
      profile: Number(userProfile?.id),
      type: payload_key.type
    }
  }, [beneficiaryDetails, pixData, values])

  const handleAlert = () => {
    setNotification({
      status: "warning",
      message: "Selecione o tipo de chave Pix e digite a chave correspondente.",
      title: "Chave Pix",
      actived: true,
      onClose: () => closeNotification(),
      zindex: "14"
    });
    setWarningFilds(true)

  }

  const fetchPixKeyBeneficiaries = useCallback(async () => {
    try {

      const { data } = await getBeneficiarieskeyPIX(Number(id))

      setRenderPixKeys(data.results.sort((b, a) => b.id - a.id).reverse())

    } catch (err) {

      console.log(err)

    }
  }, [renderPixKeys, id])


  const fetchBeneficiary = useCallback(async () => {
    try {

      const { data } = await getBeneficiaryAPI(Number(id));

      setBeneficiaryDetails(data);

    } catch (err: unknown) {

      console.error(err);
    }
  }, [id, setBeneficiaryDetails]);


  const fetchPIXverify = useCallback(async () => {

    setLoading(true);
    try {

      const response = await postCheckpixAPI(payload_key as IkeypixType);

      const data = response.data as IBankAccountInfoPix

      setPIXData(data as IBankAccountInfoPix);

      const documentNumberInput = onlyNumbers(beneficiaryDetails?.document_number || '');

      const documentNumberKeyPix = onlyNumbers(data?.document_number || "");

      if (documentNumberInput !== documentNumberKeyPix) {

        setNotification({
          status: "info",
          message: "A chave Pix adicionada não corresponde ao documento cadastrado do beneficiário.",
          title: "Verifique a chave PIX",
          actived: true,
          onClose: () => closeNotification(),
          zindex: "14"
        });

      } else {
        setNotification({
          status: "success",
          message: "Chave Pix validada e os dados cadastrados conferem.",
          title: "Chave PIX validada com sucesso",
          actived: true,
          onClose: () => closeNotification(),
          zindex: "14"
        });

      }
      setpagekeyPixError(false);

      setLoading(false);

    } catch (err) {

      setStepLoading(false);

      setpagekeyPixError(true);

      setNotification({
        status: "error",
        message: "Ocorreu um erro ao validar a chave Pix. Por favor, tente novamente.",
        title: "Erro na validação da chave PIX",
        actived: true,
        onClose: () => closeNotification(),
        zindex: "14"
      });
      console.warn(err);

    } finally {

      setLoading(false);
      
    }
  }, [payload_key, values]);


  const fetchPostPixKeyBeneficiary = useCallback(

    async () => {

      setLoading(true);

      try {
        await fetchBeneficiary();

        await postBeneficiarykeyPIX(payload)

        fetchPixKeyBeneficiaries()

        setaddkeyPixModal(false);

        setLoading(false);

        resetForm()

        setStep("initial");

        setClearFiles(true)

        setPIXData(undefined)

        setNotification({
          status: "success",
          message: "A chave PIX foi adicionada a conta com sucesso.",
          title: "Chave PIX adicionada com sucesso",
          actived: true,
          onClose: () => closeNotification(),
          zindex: "14"
        });

      } catch (err: unknown) {

        console.error(err);

        setLoading(false);

        setNotification({
          status: "success",
          message: "Não foi possível adicionar a chave PIX no momento, tente novamente mais tarde ou contate o administrador do sistema.",
          title: "Falha ao adicionar chave PIX",
          actived: true,
          onClose: () => closeNotification(),
          zindex: "14"
        });
      }
    },
    [payload, fetchBeneficiary, fetchPixKeyBeneficiaries]
  );


  useEffect(() => {

    fetchBeneficiary();

    if (clearFiles) {
      setClearFiles(false)
    }
    if (values.key.length > 1) {
      setWarningFilds(false)
    }
  }, [step, clearFiles, warningFilds, values]);

  return (
    <Modal
      radius
      height="450px"
      label={stepLoading ? "Carregando..." : "Adicionar chave PIX"}
      open={open}
      onClose={() => {
        onClose && onClose()
        setStep("initial")
        setpagekeyPixError(false)
        setPIXData(undefined)
        setClearFiles(true)
        setWarningFilds(false)
        resetForm()
      }}
      background="#f4f4f4"
      buttons={
        [
          {
            label: step === "initial" ? "Cancelar" : "Voltar",
            kind: "ghost",
            onClick: () => {
              if (step === "initial") {
                onClose && onClose()
                setpagekeyPixError(false)
                setClearFiles(true)
                setWarningFilds(false)
                resetForm()
                setPIXData(undefined)
              } else if (step === "hasKey") {
                setStep("initial")
                setpagekeyPixError(false)
                setPIXData(undefined)
                setClearFiles(true)
                resetForm()
              }
            },
          },
          {
            disabled: pagekeyPixError,
            label: step === "initial" ? "Verificar Chave" : "Salvar chave PIX",
            kind: "primary",
            onClick: async () => {
              if (step === "initial") {
                await fetchPIXverify()
                setStep("hasKey")
              } else if (step === "hasKey") {
                if (pagekeyPixError) {
                  setNotification({
                    status: "error",
                    message: "Ocorreu um erro ao validar a chave Pix. Por favor, tente novamente.",
                    title: "Erro na validação da chave PIX",
                    actived: true,
                    onClose: () => closeNotification(),
                    zindex: "14"
                  });
                  ;
                } else {
                  await fetchPostPixKeyBeneficiary();
                }
              }
            }
          }
        ]
      }
    >
      <div
        className="mx-auto w-[100%] mt-[30px]"
        style={{ borderTop: "1px solid #8D8D8D" }}>
      </div>
      <div
        className="mx-auto w-[99%] mb-[1px]"
        style={{ borderBottom: "1px solid #8D8D8D" }}
      >
        <InputFluid
          label="Banco"
          placeholder={"Instituição"}
          value={pixData ? pixData?.bank_name : ""}
          name="bank"
          readOnly
          borderNone
          noRadius
        />
      </div>
      <div onClick={() => !pixData && handleAlert()} style={{ borderBottom: "1px solid #8D8D8D" }} className="flex gap-[2px] mx-auto w-[99%]">
        <InputFluid
          id="agency"
          name="agency"
          label="Agência"
          placeholder="0000"
          type="text"
          readOnly
          value={pixData ? pixData?.agency : ""}
          inputMode="numeric"
          maxLength={8}
          borderNone
          noRadius
        />
        <InputFluid
          id="account_number"
          name="account_number"
          label="Conta"
          type="text"
          readOnly
          value={pixData ? pixData?.account_number : ""}
          placeholder="00000000"
          maxLength={16}
          noRadius
          borderNone
        />
        <div className="w-[20%] m-0 p-0 flex">
          <InputFluid
            id="account_number_digit"
            name="account_number_digit"
            label="Digito"
            readOnly
            value={pixData ? pixData?.account_number_digit : ""}
            type="text"
            placeholder="0"
            maxLength={1}
            noRadius
            borderNone
          />
        </div>
      </div>
      <div
        style={{ borderBottom: "1px solid #8D8D8D" }}
        className="flex gap-[3px] mx-auto w-[99%] mt-[1px]">
        <Dropdown
          kind="fluid"
          options={optionsPIX}
          size="medium"
          label="Tipo de chave PIX"
          placeholder="Selecione o tipo"
          noRadius
          name="type"
          onChange={handleChange}
          borderNone
          clearFiles={clearFiles}
        />
        <InputFluid
          type="text"
          label="Chave"
          placeholder="Digite a chave PIX"
          noRadius
          value={values.key}
          name="key"
          id="key"
          onChange={handleChange}
          borderNone
        />
      </div>
      <div className="flex gap-[3px] mx-auto w-[96%] mt-[1px]">
        <div className="w-[96%] flex items-center mt-[3px] gap-[10px]">
          {warningFilds && <>
            <p className="text-sm m-0 p-0">Selecione o tipo de chave Pix.</p>
            <img src={renewIcon} className="m-0 p-0" />
          </>}
        </div>
        <div className="w-[96%] flex items-center mt-[3px] gap-[10px]">
          {warningFilds && <>
            <p className="text-sm m-0 p-0">Digite a chave correspondente.</p>
            <img src={renewIcon} className="m-0 p-0" />
          </>}
        </div>
      </div>
      <Margin mb={20} />
    </Modal >
  );
};
