import Margin from "~/components/Margin";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import useUser from "~/hooks/useUser";
import { useFormik } from "formik";
import {
  getBeneficiariesBankAccount,
  getBeneficiaryAPI,
  IbeneficiaryBankAccount,
  postBeneficiaryBankAccount,
} from "~/services/api/beneficiaries";
import { getBanks } from "~/services/api/banks";
import useBanks from "~/hooks/useBanks";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { ITypeBanks } from "~/types";
import { Dropdown, InputFluid, Modal } from "prosperita-dumbo-react";
import saveIcon from "~/assets/Save.png"
import useNotification from "~/hooks/useToast";

// const clientTypeDisplay: { [key: string]: string } = {
//   client: "Cliente",
//   internal: "Atendente",
//   area_manager: "Gerente de àrea",
//   tool_manager: "Gerente de ferramenta",
// };

type IAccount = {
  account_number: string;
  agency: string;
  bank: string;
  bank_name: string;
  select: string;
  account_number_digit: string
};

type ImescleOptionsAccountPix = IAccount;

const NewAccountModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {

  const { setLoading } = useLoading();
  const { userProfile } = useUser();
  const { banks, setBanks } = useBanks();
  const { id } = useParams();

  const [clearFiles, setClearFiles] = useState<boolean>(false)

  const {
    setBeneficiaryDetails,
    beneficiaryDetails,
    setEditModalBeneficiary,
    accountBeneficiary,
    setAccountBeneficiary
  } =
    useBeneficiary();

  const { setNotification, closeNotification } = useNotification()

  const fetchBanks = useCallback(async () => {
    try {
      const { data } = await getBanks();
      setBanks(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, []);

  const validationSchema = Yup.object({
    bank: Yup.string().required("O banco é obrigatório"),
    agency: Yup.string()
      .matches(/^\d+$/, "A agência deve conter apenas números")
      .required("A agência é obrigatória"),
    account_number: Yup.string()
      .matches(/^\d+$/, "A conta deve conter apenas números")
      .required("O número da conta é obrigatório"),
  });


  const InitialValues: ImescleOptionsAccountPix =
  {
    account_number: "",
    agency: "",
    bank: "",
    bank_name: "",
    select: "",
    account_number_digit: ""
  };

  const {
    values,
    handleChange,
    validateForm,
    setTouched,
    resetForm,
  } = useFormik({
    initialValues: InitialValues,
    validationSchema,
    onSubmit: () => console.log(""),
  });


  const banksOptions = useMemo(() => {
    return banks
      ?.map((banks: ITypeBanks) => ({
        value: String(banks.id),
        label: String(`${banks.code} - ${banks.description}`),
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [banks]);

  const fetchBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiaryAPI(Number(id));
      setBeneficiaryDetails(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [id, beneficiaryDetails]);

  const fetchBankAccountBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiariesBankAccount(Number(id))
      setAccountBeneficiary(data.results.sort((a, b) => b.id - a.id));
    } catch (err) {
      console.log(err)
    }

  }, [accountBeneficiary])

  const payload: IbeneficiaryBankAccount = useMemo(() => {
    return {
      agency: values.agency,
      account_number: values.account_number,
      account_number_digit: values.account_number_digit,
      profile: Number(userProfile?.id),
      beneficiary: Number(id),
      bank: Number(values.bank),
      status: "waiting_approval",
      active: false,
    };
  }, [values, beneficiaryDetails, userProfile]);

  const fetchAccountBeneficiary = useCallback(
    async () => {
      setLoading(true);
      try {
        await postBeneficiaryBankAccount(payload)
        await fetchBankAccountBeneficiary()
        setEditModalBeneficiary(false);
        setLoading(false);
        setNotification({
          status: "success",
          message: "A conta bancária foi adicionada com sucesso.",
          title: "Adicionada com sucesso",
          actived: true,
          zindex: "20",
          onClose: () => closeNotification
        });
        setClearFiles(true)
        resetForm();
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        setNotification({
          status: "error",
          message: "Não foi possível adicionar a conta bancária no momento, tente novamente mais tarde ou contate o administrador do sistema.",
          title: "Falha ao adicionar conta bancária",
          actived: true,
          zindex: "20",
          onClose: () => closeNotification
        });
      }
    },
    [payload, fetchBeneficiary, accountBeneficiary]
  );


  useEffect(() => {
    fetchBeneficiary();
    fetchBanks();
  }, []);

  useEffect(() => {
    if (clearFiles) {
        setClearFiles(false);
    }
}, [clearFiles]);

  return (
    <Modal
      radius
      background="#f4f4f4"
      height="500px"
      width="600px"
      onClose={() => {
        onClose && onClose()
        setClearFiles(true)
      }}
      label={<h4 className="mt-[8px]">Adicionar nova conta bancária</h4>}
      open={open}
      buttons={
        [

          {
            size: "large",
            dimension: "75",
            kind: "ghost",
            label: "Cancelar",
            onClick: () => {
              setEditModalBeneficiary(false)
              resetForm();
              setClearFiles(true)
            }
          },
          {
            size: "large",
            dimension: "75",
            kind: "primary",
            icon: saveIcon,
            label: "Salvar conta bancária",
            onClick: async () => {
              const formErrors = await validateForm();
              if (Object.keys(formErrors).length === 0) {
                await fetchAccountBeneficiary();
                onClose();
              } else {
                setTouched({
                  bank: true,
                  agency: true,
                  account_number: true,
                });
                setNotification({
                  status: "error",
                  message: "Preencha todos os campos obrigatórios para cadastrar a conta.",
                  title: "Campos Obrigatórios",
                  actived: true,
                  zindex: "20",
                  onClose: () => closeNotification()
                });
              }
            }
          }
        ]
      }
    >
      {" "}
      <Margin mb={0} />
      <div className="mx-auto w-[100%] mt-[30px]" style={{ borderTop: "1px solid #8D8D8D" }}></div>
      <div className="mx-auto w-[99%]" style={{ borderBottom: "1px solid #8D8D8D" }}>
        <Dropdown
          kind="fluid"
          size="medium"
          label="Banco"
          clearFiles={clearFiles}
          onChange={handleChange}
          options={banksOptions}
          placeholder={"Selecione uma opção"}
          name="bank"
          borderNone
          noRadius
        />
      </div>
      <div style={{ borderBottom: "1px solid #8D8D8D" }} className="flex gap-[2px] mx-auto w-[99%]">
        <InputFluid
          id="agency"
          name="agency"
          label="Agência"
          placeholder="0000"
          type="text"
          onChange={handleChange}
          inputMode="numeric"
          maxLength={8}
          borderNone
          noRadius
          value={values.agency}
        />
        <InputFluid
          id="account_number"
          name="account_number"
          label="Conta"
          type="text"
          placeholder="00000000"
          onChange={handleChange}
          maxLength={16}
          noRadius
          borderNone
          value={values.account_number}
        />
        <div className="w-[20%] m-0 p-0 flex">
          <InputFluid
            id="account_number_digit"
            name="account_number_digit"
            label="Digito"
            type="text"
            placeholder="0"
            onChange={handleChange}
            maxLength={1}
            noRadius
            borderNone
            value={values.account_number_digit}
          />
        </div>
      </div>
      <div style={{ borderBottom: "1px solid #8D8D8D" }} className="flex gap-[3px] mx-auto w-[99%] mt-[1px]">
        <InputFluid
          id="cpf"
          name="cpf"
          label="CPF do co-titular"
          placeholder="0000"
          onChange={handleChange}
          inputMode="numeric"
          maxLength={3}
          noRadius
          borderNone
        />
        <InputFluid
          id="identification"
          name="identification"
          label="Identificação do co-titular"
          placeholder="0"
          onChange={handleChange}
          maxLength={12}
          noRadius
          borderNone
        />
      </div>
      <Margin mb={20} />
    </Modal>
  );
};

export default NewAccountModal;
