import { Toggle } from "@carbon/react";
import { InputFluid, Modal } from "prosperita-dumbo-react";
import Margin from "~/components/Margin";
import { useCallback, useEffect, useMemo } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import { useFormik } from "formik";
import {
  getBeneficiariesAPI,
  IBodyPatchBeneficiary,
  patchBeneficiaryAPI,
} from "~/services/api/beneficiaries";
import * as Yup from "yup";
import useNotification from "~/hooks/useToast";


const ChangeStatusBeneficiaryModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();

  const {
    setBeneficiaries,
    setEditStatusBeneficiaryModal,
    beneficiaryDetails,
    setEditModalBeneficiary,
  } = useBeneficiary();

  const { setNotification, closeNotification } = useNotification()

  const validationSchema = Yup.object({
    status: Yup.string().required("O Status é obrigatório"),
  });

  const InitialValues = {
    active: false,
  };

  const { resetForm, setFieldValue, values } = useFormik({
    initialValues: InitialValues,
    validationSchema,
    onSubmit: () => console.log(""),
  });

  const handleToggle = () => {
    setFieldValue("active", !values.active);
  };

  const fetchBeneficiaries = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getBeneficiariesAPI();
      console.log(data);
      setBeneficiaries(data.results.reverse());
      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [setLoading, setBeneficiaries]);

  const payload: IBodyPatchBeneficiary = useMemo(() => {
    const status = values.active === true ? "approved" : "inactive";
    return {
      raw_data: {
        pending_funds_approvations:
          beneficiaryDetails?.raw_data?.pending_funds_approvations,
        status
      },
    };
  }, [values]);

  const fetchPatchBeneficiary = useCallback(
    async (id: number) => {
      setLoading(true);
      try {
        await patchBeneficiaryAPI(id, payload);
        setEditModalBeneficiary(false);
        await fetchBeneficiaries();
        setEditStatusBeneficiaryModal(false);
        setLoading(false);
        setNotification({
          status: "success",
          message: "O beneficiário foi editado com sucesso.",
          title: "Editado com sucesso",
          actived: true,
          zindex: "20",
          onClose: () => closeNotification
        });
        resetForm();
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        setNotification({
          status: "error",
          message: "Não foi possível editar o beneficiário no momento, tente novamente mais tarde ou contate o administrador do sistema.",
          title: "Falha ao editar beneficiário",
          actived: true,
          zindex: "20",
          onClose: () => closeNotification
        });
      }
    },
    [
      payload,
      fetchBeneficiaries,
      resetForm,
      setEditModalBeneficiary,
      setEditStatusBeneficiaryModal,
      setLoading,
    ]
  );

  useEffect(() => {
    if (beneficiaryDetails?.status === "approved") {
      setFieldValue("active", true);
    } else {
      setFieldValue("active", false);
    }
  }, [beneficiaryDetails, setFieldValue]);

  return (
    <Modal
      background="#f4f4f4"
      height="450px"
      label={
        <>
          <h3 className="text-[23px] leading-[28px] m-0 p-0 my-2">
            Alterar Status Beneficiário
          </h3>
          <p className="text-[15px] font-[400]">Você está alterando o status do beneficiário abaixo:</p>
        </>
      }
      open={open}
      onClose={onClose}
      buttons={[
        {
          label: "Cancelar",
          kind: "ghost",
          onClick: () => {
            onClose()
            resetForm()
          }
        },
        {
          label: "Concluir",
          kind: "primary",
          onClick: async () =>
            fetchPatchBeneficiary(Number(beneficiaryDetails?.id))

        }
      ]}

    >
      <Margin mb={20} />
      <div style={{ borderTop: "2px solid #c6c6c6" }}></div>
      <div className="w-[98%] mx-auto">
        <InputFluid
          noRadius
          type="text"
          label="Nome"
          borderNone
          value={beneficiaryDetails?.name}
          readOnly
        />
        <Margin mb={2} />
        <InputFluid
          noRadius
          type="text"
          label="CPF"
          borderNone
          value={beneficiaryDetails?.document_number}
          readOnly
        />
        <Margin mb={13} />
        <div className="w-[96%] mx-auto">
          <Toggle
            id="toggle-2"
            labelText="Status"
            defaultChecked={values.active}
            onToggle={handleToggle}
            toggled={values.active}
            labelA="Inativo"
            labelB="Ativo"
          />
        </div>
      </div>
      <Margin mb={24} />
    </Modal>
  );
};

export default ChangeStatusBeneficiaryModal;
