import "./index.css";
import "carbon-components-react/scss/grid/_index.scss"
import "carbon-components-react/scss/components/_index.scss"
import { RouterProvider } from "react-router-dom";
import AppRouter from "./routes/AppRouter";
import pipe from "./utils/pipe";
import withProvider from "./utils/withProvider";
import { AuthProvider } from "./hooks/useAuth";
import { AttendProvider } from "./hooks/useAttend";
import { Loading } from "@carbon/react";
import useLoading, { LoadingProvider } from "./hooks/useLoading";
import { UserProvider } from "./hooks/useUser";
import { TaskProvider } from "./hooks/useTask";
import { ProfileProvider } from "./hooks/useProfile";
import "bootstrap/dist/css/bootstrap.min.css";
import { FundProvider } from "./hooks/useFund";
import { AreaProvider } from "./hooks/useArea";
import { AreaTypeProvider } from "./hooks/useAreaType";
import { AttendTypeProvider } from "./hooks/useType";
import { PaymentProvider } from "./hooks/usePayment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "prosperita-dumbo-react/dist/style.css";
import { CompanyProvider } from "./hooks/useCompany";
import { CompanyTypeProvider } from "./hooks/useCompanyType";
import { PaymentTypeProvider } from "./hooks/useTypesPayment";
import { BeneficiaryProvider } from "./hooks/useBeneficiary";
import { BanksProvider } from "./hooks/useBanks";
import "bootstrap/dist/css/bootstrap.min.css";
import { StatusProvider } from "./hooks/useStatus";
import { Notification } from "prosperita-dumbo-react";
import useNotification, { NotificationProvider } from "./hooks/useToast";
import { useEffect } from "react";

// import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {

  const { loading } = useLoading();

  const { notification, setNotification } = useNotification()

  useEffect(() => {
    if (notification?.actived) {
      const timeoutId = setTimeout(() => {
        setNotification((prev) => ({ ...prev, actived: false }));
      }, 6000);
      return () => clearTimeout(timeoutId);
    }
  }, [notification, setNotification]);

  return (
    <main>
      <RouterProvider router={AppRouter} />
      <Loading withOverlay={true} active={loading} style={{ zIndex: 4 }} />
      <Notification
        zindex={notification?.zindex || "10"}
        top={notification?.top || "130px"}
        right={notification?.right || "30px"}
        onClose={notification?.onClose}
        noRadius
        status={notification?.status || "info"}
        actived={notification?.actived || false}
        title={notification?.title}
        message={notification?.message}
      >
      </Notification>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </main>
  );
};

const enhance = pipe(
  withProvider(
    UserProvider,
    LoadingProvider,
    AuthProvider,
    AttendProvider,
    TaskProvider,
    ProfileProvider,
    FundProvider,
    AreaProvider,
    AreaTypeProvider,
    AttendTypeProvider,
    CompanyProvider,
    CompanyTypeProvider,
    PaymentProvider,
    PaymentTypeProvider,
    BeneficiaryProvider,
    BanksProvider,
    StatusProvider,
    NotificationProvider
  )
);

const EnhancedApp = enhance(App);

export default EnhancedApp;
