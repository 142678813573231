import { Button, Dropdown, InputFluid } from "prosperita-dumbo-react"
import { ItypeRenderRegister } from "./Register"
import Margin from "~/components/Margin"
import { useCallback, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import useLoading from "~/hooks/useLoading"
import useUser from "~/hooks/useUser"
import {
    IBodyBeneficiary,
    postBeneficiaryAPI,
    postBeneficiaryBankAccount,
    postBeneficiaryFundAPI,
    postBeneficiarykeyPIX
} from "~/services/api/beneficiaries"
import useNotification from "~/hooks/useToast"
import uf from "./uf.json"
import useBeneficiary from "~/hooks/useBeneficiary"

export const Address = ({ formik }: ItypeRenderRegister) => {

    const { handleChange, values, resetForm } = formik

    const navigate = useNavigate();

    const { setLoading } = useLoading();

    const { userProfile } = useUser();

    const { setTab } = useBeneficiary()

    const { setNotification, closeNotification } = useNotification()

    const { setMainTab } = useBeneficiary()

    const payload: IBodyBeneficiary = useMemo(() => {
        return {
            name: values.name,
            document_number: values.document_number,
            active: false,
            profile: Number(userProfile?.id),
            status: "waiting_approval",
            raw_data: {
                pending_funds_approvations: true,
                address: {
                    postalcode: values.postalcode,
                    neighborhood: values.neighborhood,
                    city: values.city,
                    uf: values.uf,
                    street: values.street,
                    number: values.number,
                    referência: values.reference
                }
            },
        };
    }, [values, userProfile]);


    const fetchFundBeneficiary = useCallback(async (fundId: number, beneficiary: number) => {
        try {
            const { data } = await postBeneficiaryFundAPI({
                fund: fundId,
                beneficiary: Number(beneficiary),
                raw_data: {
                    status: "waiting_approval",
                },
            });
            console.log(data);

        } catch (e) {

            console.warn("erro ao cadastrar fundo");
        }
    }, [])


    const createNewBeneficiary = useCallback(async () => {

        setLoading(true);

        try {

            const { data } = await postBeneficiaryAPI(payload as IBodyBeneficiary);

            if (values.fund) {
                const fetchPromises = values.fund.map(async (fundId) => {
                    try {
                        return await fetchFundBeneficiary(Number(fundId?.value), Number(data.id));
                    } catch (error) {
                        return null;
                    }
                });
                await Promise.all(fetchPromises);
            }
            if (values.banking_account !== "" &&
                values.banking_agency !== "" && values.banking_name !== "") {
                await postBeneficiaryBankAccount({
                    account_number: values.banking_account,
                    account_number_digit:
                        values.banking_account.split("")[values.banking_account.length - 1],
                    agency: values.banking_agency,
                    bank: Number(values.banking_name),
                    profile: Number(userProfile?.id),
                    beneficiary: Number(data?.id),
                    status: "waiting_approval",
                });
            }
            if (values.type !== "" && values.key !== "") {
                await postBeneficiarykeyPIX({
                    type: values.type,
                    key: values.key,
                    active: true,
                    profile: Number(userProfile?.id),
                    beneficiary: data.id,
                })
            }

            setNotification({
                status: "success",
                message: "Beneficiário adicionado com sucesso.",
                title: "Sucesso",
                actived: true,
                onClose: () => closeNotification()
            });

            resetForm && resetForm();

            setLoading(false);

            setMainTab("waiting_aprovall")

            setTab("general")
            
            navigate("/app/beneficiaries/");

        } catch (err: unknown) {

            setLoading(false);

            setNotification({
                status: "error",
                message: "Não foi possível adicionar o beneficiário no momento, tente novamente mais tarde ou contate o administrador do sistema",
                title: "Erro ao adicionar beneficiário",
                actived: true,
                onClose: () => closeNotification()
            });
        }
    }, [
        payload,
        resetForm,
        setLoading,
        values,
        fetchFundBeneficiary
    ]);


    console.log(payload)

    return (
        <div className="block w-[100%]">
            <div className="flex w-[100%] flex-col gap-[1px] mt-10 bg-[#f4f4f4]">
                <div style={{ borderBottom: "1px solid #8d8d8d" }}>
                    <InputFluid
                        type="text"
                        label="CEP"
                        placeholder="11000-999"
                        noRadius
                        id={"postalcode"}
                        name="postalcode"
                        onChange={handleChange}
                        borderNone
                    />
                </div>
                <div style={{ borderBottom: "1px solid #8d8d8d" }} className="flex gap-[3px]">
                    <InputFluid
                        type="text"
                        label="Bairro"
                        placeholder="Vila Guarani"
                        noRadius
                        id={"neighborhood"}
                        name="neighborhood"
                        value={values.neighborhood}
                        onChange={handleChange}
                        borderNone
                    />
                    <InputFluid
                        type="text"
                        label="Cidade"
                        placeholder="São Paulo"
                        noRadius
                        id={"city"}
                        name={"city"}
                        value={values.city}
                        onChange={handleChange}
                        borderNone
                    />
                    <Dropdown
                        kind="fluid"
                        options={uf}
                        size="medium"
                        label="UF"
                        name="uf"
                        id="uf"
                        onChange={handleChange}
                        placeholder="SP"
                        noRadius
                        borderNone
                    />
                </div>
                <div style={{ borderBottom: "1px solid #8d8d8d" }} className="flex gap-[3px]">
                    <InputFluid
                        type="text"
                        label="Logradouro"
                        placeholder="Digite o Nome do Logradouro"
                        noRadius
                        name="street"
                        value={values.street}
                        onChange={handleChange}
                        borderNone
                    />
                    <InputFluid
                        type="text"
                        label="Número"
                        placeholder="000"
                        noRadius
                        id={"number"}
                        name={"number"}
                        value={values.number}
                        onChange={handleChange}
                        borderNone
                    />
                    <InputFluid
                        type="text"
                        label="Complemento"
                        placeholder="Casa"
                        value={values.reference}
                        noRadius
                        name="reference"
                        onChange={handleChange}
                        borderNone
                    />
                </div>
            </div>
            <Margin mt={40} />
            <div className="flex w-[100%] justify-end">
                <Button
                    label={"Concluir"}
                    kind="primary"
                    size="large"
                    onClick={() => createNewBeneficiary()}
                ></Button>
            </div>
        </div>
    )
}
