import { FormikErrors, FormikState, useFormik } from "formik"
import { Breadcrumb, Tab, TabPane } from "prosperita-dumbo-react"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import Margin from "~/components/Margin"
import { General } from "./General"
import { Address } from "./Address"
import { FormikHandlers } from 'formik';
import useBeneficiary from "~/hooks/useBeneficiary"

type Values = {
    name: string;
    document_number: string;
    account_number: string;
    account_number_digit: string;
    agency: string;
    bank: string;
    telefone: string;
    evp: string;
    email: string;
    type: "telefone" | "cpf" | "cnpj" | "email" | "evp" | "";
    fund: [{ label?: string, value?: string }];
    beneficiary: string;
    key: string;
    banking_agency: string;
    banking_account: string;
    banking_name: string;
    neighborhood: string,
    city: string,
    uf: string,
    street: string,
    number: string,
    reference: string,
    postalcode: string,
};

const initialValues: Values = {
    name: "",
    document_number: "",
    email: "",
    evp: "",
    telefone: "",
    account_number: "",
    account_number_digit: "",
    agency: "",
    bank: "",
    type: "evp",
    fund: [{}],
    beneficiary: "",
    key: "",
    banking_agency: "",
    banking_account: "",
    banking_name: "",
    neighborhood: "",
    city: "",
    uf: "",
    street: "",
    number: "",
    reference: "",
    postalcode: ""
};

type IrenderType = {
    content: JSX.Element,
    position?: "center" | "right" | "left"
    tab: "general" | "address"
    size?: string
    label: string
    disabled?: boolean
}[]

export type ItypeRenderRegister = {
    formik: {
        values: Values;
        handleChange: FormikHandlers['handleChange'];
        setFieldValue?: (field: string, value: unknown, shouldValidate?: boolean) =>
            Promise<void> | Promise<FormikErrors<Values>>,
        resetForm?: (nextState?: Partial<FormikState<Values>> | undefined) => void
    }
};

export const RegisterBeneficiary = () => {

    const navigate = useNavigate()

    const { pixToggle, tab, setTab } = useBeneficiary()

    const { values, handleChange, setFieldValue, resetForm } = useFormik({
        initialValues,
        onSubmit: () => { }
    })

    const isValidButton = useCallback(() => {
        if (pixToggle) {
            return (
                values.key !== "cnpj" &&
                values.key !== "cpf" &&
                values.key !== "telefone" &&
                values.key !== "email" &&
                values.key !== "evp" &&
                values.key.length >= 5
            );
        }
        const hasTwoNames =
            values.name && values.name.trim().split(" ").length >= 2;
        const hasDocumentNumber =
            values.document_number.length === 11 ||
            values.document_number.length >= 11;
        return hasTwoNames && hasDocumentNumber && values.fund.length > 0;
    }, [pixToggle, values]);

    const renderRegisters: IrenderType = [
        {
            content: <General formik={{ values, handleChange, setFieldValue, resetForm }} />,
            position: "center",
            tab: "general",
            label: "Geral"
        },
        {
            content: <Address formik={{ values, handleChange, setFieldValue, resetForm }} />,
            tab: "address",
            size: "100%",
            label: "Endereço",
            disabled: !isValidButton()
        }
    ]

    return (
        <>
            <div className="w-full mx-auto">
                <div className="bg-[#f4f4f4] h-[140px] m-0 w-full">
                    <div className="flex flex-col">
                        <div className="mt-[40px] ml-[30px] w-1/2 flex flex-col items-start text-left">
                            <Breadcrumb
                                crumbsOptions={[
                                    { crumb: "Beneficiários", onClick: () => navigate("/app/beneficiaries") },
                                    { crumb: "Novo beneficiário" },
                                ]}
                                endHiddenIndex={5}
                                startHiddenIndex={5}
                            />
                        </div>
                        <h2 className="mt-3 ml-[30px] text-black text-[40px] font-light leading-[40px]">
                            Novo beneficiário
                        </h2>
                    </div>
                </div>
            </div>
            <Margin mb={20} />
            <div className="w-[96%] m-0 p-0 mx-auto">
                <Tab width="100%">
                    {renderRegisters.map((regs) => (
                        <TabPane
                            width={regs.size || "100px"}
                            position={regs.position || "left"}
                            isActive={tab === regs.tab}
                            disabled={regs.disabled}
                            label={regs.label}
                            onClick={() => setTab(regs.tab)}
                            bordercolor="rgb(10, 74, 135)"
                        >
                            {regs.content}
                        </TabPane>
                    ))}
                </Tab >
            </div >
        </>
    )
}
