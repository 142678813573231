import Margin from "~/components/Margin";
// import { formatCPFCNPJ } from "~/services/utils";
import {
  Breadcrumb,
  TableBasic,
  Button,
  Tab,
  TabPane
} from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useBeneficiary from "~/services/../hooks/useBeneficiary";
import useLoading from "~/hooks/useLoading";
import NewAccountModal from "./BankAccount/AddNewAccountModal";
import {
  getBeneficiariesBankAccount,
  getBeneficiarieskeyPIX,
  getBeneficiaryAPI,
  getBeneficiaryFundWIDAPI,
} from "~/services/api/beneficiaries";
import DeleteAccountBeneficiary from "./BankAccount/DeleteAccountBeneficiaryModal";
import { getBanks } from "~/services/api/banks";
import useBanks from "~/hooks/useBanks";
// import EditBankAccountBeneficiaryModal from "./EditBankAccountBeneficiaryModal";
import { AddModalPixkey } from "./PIX/AddModalPixkey";
import { DeleteModalKeyPix } from "./PIX/DeleteModalPixKey";
import ApproveBeneficiaryModal from "./ApproveBeneficiaryModal";
// import ApproveModalAccountBeneficiary from "./ApproveModalAccountBeneficiary";
import Badge from "~/components/Badge";
import ReproveBeneficiaryModal from "./ReproveModalBeneficiary";
import AddBeneficiaryFundModal from "./Fund/AddBeneficiaryFundModal";
import useUser from "~/hooks/useUser";
import ApproveFundBeneficiaryModal from "./ApproveReproveFunds/ApproveFundBeneficiaryModal";
import ReproveFundBeneficiaryModal from "./ApproveReproveFunds/ReproveBeneficiaryFundModal";
import ApproveModalAccountBeneficiary from "./BankAccount/ApproveModalAccountBeneficiary"
import ReproveAccountModalBeneficiary from "./BankAccount/ReproveAccountModalBeneficiary";
import userIcon from "~/assets/User.png"
import 'typeface-ibm-plex-mono';
import { formatCPFCNPJ } from "~/services/utils";
import trashIcon from "~/assets/trashIcon.png"
import renewIcon from "~/assets/RenewIcon.png"


const tableInfoAccount: string[] = [
  "Instituição",
  "Agencia",
  "Conta",
  "Status",
  "",
  "",
];

const tableInfoFund: string[] = [
  "Fundo",
  "Nome abreviado",
  "CPF/CNPJ",
  "Status",
  "Autenticação",
  "Associação",
  "",
];

const tablepixTypeAndKey: string[] = [
  "Instituição",
  "Tipo de chave",
  "Chave",
  "Status",
  ""
]

const renderStatusBankAccounts: { [key: string]: React.ReactNode } = {
  inactive: <Badge label="Inativa" color="#e3e3e3" textColor="#696868" />,

  waiting_approval: (
    <Badge color="#ffe9c9" textColor="#b46c00" label="Em aprovação" />
  ),
  approved: <Badge color="#CCEFDF" textColor="#00B360" label="Ativa" />,

  reproved: <Badge label="Reprovada" textColor="#ff0000" color="#f9c6c6" />,
};

// const renderStatusFunds: { [key: string]: React.ReactNode } = {
//   inactive: <Badge label="Inativo" color="#e3e3e3" textColor="#696868" />,

//   waiting_approval: (
//     <Badge color="#ffe9c9" textColor="#b46c00" label="Em aprovação" />
//   ),
//   approved: <Badge color="#CCEFDF" textColor="#00B360" label="Ativo" />,

//   reproved: <Badge label="Reprovado" textColor="#ff0000" color="#f9c6c6" />,
// };

const renderStatus: { [key: string]: React.ReactNode } = {
  inactive: <Badge label="Inativo" color="#e3e3e3" textColor="#696868" />,

  waiting_approval: (
    <Badge color="#ffe9c9" textColor="#b46c00" label="Aguardando aprovação" />
  ),
  approved: <Badge color="#CCEFDF" textColor="#00B360" label="Ativo" />,

  reproved: <Badge label="Reprovado" textColor="#ff0000" color="#f9c6c6" />,
};

const BeneficiaryDetails = () => {

  const { id } = useParams();

  const {
    setBeneficiaryFundDetails,
    beneficiaryDetails,
    setBeneficiaryDetails,
    setEditModalBeneficiary,
    editModalBeneficiary,
    setAccountBeneficiary,
    accountBeneficiary,
    setDeleteModalAccountBeneficiary,
    deleteAccountModalBeneficiary,
    renderPixKeys,
    setRenderPixKeys,
    setaddkeyPixModal,
    addkeyPixModal,
    setpixkeyUser,
    appReproveAccountBeneficiaryModal,
    setappReproveAccountBeneficiaryModal,
    setdeleteModalKeyPix,
    deleteModalKeyPix,
    setOpenApproveBeneficiaryModal,
    openApproveBeneficiaryModal,
    setAppApproveAccountBeneficiaryModal,
    appApproveAccountBeneficiaryModal,
    setOpenReproveBeneficiaryModal,
    openReproveBeneficiaryModal,
    setAddBeneficiaryFundModal,
    addBeneficiaryFundModal,
    setApproveBeneficiaryFundModal,
    approveBeneficiaryFundModal,
    setFundsBeneficiaryRender,
    fundsBeneficiaryRender,
    reproveBeneficiaryFundModal,
    setReproveBeneficiaryFundModal,
    setBeneficiaryAccounts,
  } = useBeneficiary();

  const [currentPageFunds] = useState(1);

  const [itemsPerPageFunds] = useState(20);

  const storedTab = localStorage.getItem("activeTab");

  const [tab, setTab] = useState<"funds" | "banks" | "pix">(storedTab as "funds" | "banks" | "pix" || "funds");

  const handleTabChange = (newTab: "funds" | "banks" | "pix") => {
    setTab(newTab);
    localStorage.setItem("activeTab", newTab);
  };

  const paginatedFunds = useMemo(() => {
    const waitingApprovalFunds =
      fundsBeneficiaryRender
        ?.filter((fund) => fund.raw_data.status === "waiting_approval")
        .sort((a, b) => a.fund.name.localeCompare(b.fund.name)) || [];
    const otherFunds =
      fundsBeneficiaryRender
        ?.filter((fund) => fund.raw_data.status !== "waiting_approval")
        .sort((a, b) => a.fund.name.localeCompare(b.fund.name)) || [];
    const combinedFunds = [...waitingApprovalFunds, ...otherFunds];
    const start = (currentPageFunds - 1) * itemsPerPageFunds;
    const end = start + itemsPerPageFunds;
    return combinedFunds.slice(start, end);
  }, [fundsBeneficiaryRender, currentPageFunds, itemsPerPageFunds]);

  const optionskey = useMemo(() => {
    return [
      { label: "CNPJ", value: "cnpj" },
      { label: "CPF", value: "cpf" },
      { label: "Celular", value: "telefone" },
      { label: "Email", value: "email" },
      { label: "Aleatória", value: "evp" },
    ] as const;
  }, []);

  const navigate = useNavigate();

  const { banks, setBanks } = useBanks();

  const { userProfile } = useUser();

  const { setLoading } = useLoading();

  const fetchFundsBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiaryFundWIDAPI(Number(id));

      setFundsBeneficiaryRender(data?.results.reverse());

    } catch {

      console.warn("erro");
    }
  }, [id, setFundsBeneficiaryRender]);



  const fetchBeneficiary = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getBeneficiaryAPI(Number(id));
      setBeneficiaryDetails(data);
      setLoading(false);
    } catch {
      console.warn("erro ao fazer requisição");
    }
  }, [setBeneficiaryDetails, id, setLoading]);

  
  const fetchBanks = useCallback(async () => {
    try {
      const { data } = await getBanks();
      setBanks(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, []);

  const fetchBankAccountBeneficiary = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await getBeneficiariesBankAccount(Number(id))
      setAccountBeneficiary(data.results.sort((a, b) => b.id - a.id));
      setLoading(false)
    } catch (err) {
    }

  }, [accountBeneficiary, id])

  const fetchPixKeyBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiarieskeyPIX(Number(id))
      setRenderPixKeys(data.results.sort((b, a) => b.id - a.id))
    } catch (err) {
      console.warn(err)
    }
  }, [renderPixKeys, id])


  const BanksObject = useMemo(() => {
    return banks.reduce(
      (acc, bank) => {
        acc[bank.id] = {
          id: bank.id,
          name: bank.name,
          code: bank.code,
          description: bank.description,
        };
        return acc;
      },
      {} as Record<
        number,
        { id: number; name: string; code: string; description: string }
      >
    );
  }, [banks]);

  const isBeneficiaryApprover = useMemo(() => {

    return userProfile?.beneficiary_approver;

  }, [userProfile]);


  const dataAccountBeneficiary = useMemo(() => {

    return accountBeneficiary?.map((account) => ({
      "Instituição":
        <div className="m-0 p-0">
          {BanksObject[Number(account.bank)]?.code +
            " - " +
            BanksObject[Number(account.bank)]?.description ||
            "Banco não encontrado"}
        </div>,
      "Agencia": <p className="m-0 p-0">{account.agency}</p>,
      "Conta": <p className="m-0 p-0" >  {account.account_number}-
        {account.account_number_digit}{" "}</p>,
      "Status":
        <p className="m-0 p-0 w-[100px]">
          {
            account?.status &&
            renderStatusBankAccounts[account?.status]
          }
        </p>,
      "button":
        <div>
          {account.status === "waiting_approval" &&
            isBeneficiaryApprover && (
              <>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div className="w-[76px]">
                      <Button
                        noPadding="4px"
                        label="Reprovar"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setappReproveAccountBeneficiaryModal(true),
                            setBeneficiaryAccounts([account]);

                        }}
                        kind="dangerPrimary"
                      >
                      </Button>
                    </div>
                    <Margin ml={10} />
                    <div className="w-[70px]">
                      <Button
                        noPadding="4px"
                        label={"Aprovar"}
                        size="small"
                        kind="tertiary"
                        onClick={(e) => {
                          e.stopPropagation();
                          setAppApproveAccountBeneficiaryModal(true)
                          setBeneficiaryAccounts([account]);
                        }}
                      >
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
        </div>,
      remover:
        <div className="m-0 p-0 w-[30px]">
          {account.status !== "waiting_approval" && (
            <Button
              noPadding="3px"
              kind="ghost"
              icon={trashIcon}
              size="small"
              onClick={() => {
                setBeneficiaryAccounts([account]);
                setDeleteModalAccountBeneficiary(true);
              }}
            />
          )}
        </div>
    }))

  }, [accountBeneficiary, userProfile])

  const dataFunds = useMemo(() => {

    return paginatedFunds.map((beneficiaryFund) => ({

      Fundo:
        <p style={{
          whiteSpace: "break-spaces",
          width: "350px",
          margin: "0",
          padding: "0"
        }}>
          {beneficiaryFund.fund.name}
        </p>
      ,
      "Nome abreviado":
        <p style={{
          whiteSpace: "break-spaces",
          width: "200px",
          margin: "0",
          padding: "0"
        }}>{beneficiaryFund.fund.raw_data.short_name}</p>,

      "CPF/CNPJ": beneficiaryFund.fund.raw_data.document_number,

      "Status":
        <div>
          {
            beneficiaryFund.fund.raw_data.active ? (
              <Badge
                color="#CCEFDF"
                textColor="#00B360"
                label="Ativo"
              />
            ) : (
              <Badge
                label="Inativo"
                color="#e3e3e3"
                textColor="#696868"
              />
            )
          }
        </div>,
      "Autenticação":
        <div style={{ width: "100px" }}>
          {" "}
          {beneficiaryFund?.fund?.raw_data?.authenticated ? (
            <Badge
              color="#bacbff"
              textColor="#1906ec"
              label="Autenticado"
            />
          ) : (
            <Badge
              label="Não autenticado"
              color="#e3e3e3"
              textColor="#696868"
            />
          )}
        </div>,
      "": "",
      "aproval":
        <>
          {beneficiaryFund.raw_data.status ===
            "waiting_approval" &&
            isBeneficiaryApprover && (
              <>
                <div
                  className="flex gap-[20px]"
                >
                  <div>
                    <Button
                      size="small"
                      noPadding="5px"
                      label={"Reprovar"}
                      onClick={(e) => {
                        e.stopPropagation();
                        setBeneficiaryFundDetails(
                          beneficiaryFund
                        );
                        setReproveBeneficiaryFundModal(true);
                      }}
                      kind="dangerPrimary"
                    >
                    </Button>
                  </div>
                  <div className="p-0 m-0">
                    <Button
                      kind="tertiary"
                      noPadding="5px"
                      label={"Aprovar"}
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setBeneficiaryFundDetails(
                          beneficiaryFund
                        );
                        setApproveBeneficiaryFundModal(true);
                      }}
                    >
                    </Button>
                  </div>
                </div>
              </>
            )}
        </>,
    }))
  }, [paginatedFunds, userProfile])

  console.log(renderPixKeys, "TESTE")

  const dataPIXbeneficiary = useMemo(() => {

    return renderPixKeys?.map((pixInfo) => ({
      Instituição: "",
      "Tipo de chave":
        <>
          {optionskey.map((items) => (
            <>
              {items.value === pixInfo.type
                ? items.label
                : ""}
            </>
          ))}
        </>,
      Chave: pixInfo.key,
      status:
        <div className="w-[80px]">
          {pixInfo.active && renderStatus.approved}
        </div>,
      "":
        <div className="m-0 p-0 w-[30px]">
          <Button
            noPadding="3px"
            size="small"
            kind="ghost"
            icon={trashIcon}
            onClick={() => {
              setpixkeyUser(pixInfo);
              setdeleteModalKeyPix(true);
            }}
          />
        </div >
    }))
  }, [renderPixKeys])


  useEffect(() => {
    fetchBeneficiary();
    fetchBanks();
    fetchFundsBeneficiary();
    fetchBankAccountBeneficiary()
    fetchPixKeyBeneficiary()
  }, []);

  return (
    <>
      <div className="bg-[#f4f4f4] h-[160px] m-0 w-full">
        <div className="flex flex-col">
          <div className="mt-[40px] ml-[36px] w-1/2 flex flex-col items-start text-left">
            <Breadcrumb
              crumbsOptions={[
                { crumb: "Configurações", onClick: () => navigate("/app/beneficiaries"), },
                { crumb: beneficiaryDetails?.name || "" },
              ]}
              endHiddenIndex={5}
              startHiddenIndex={5}
            />
          </div>
          <h2 className="mt-4 ml-[36px] text-black text-[40px] font-light leading-[40px]">
            Beneficiários
          </h2>
        </div>
      </div>
      <div style={{ width: "95%", margin: "0 auto" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            fontWeight: 100,
            alignItems: "center",
            margin: 0,
            padding: 0,
          }}
        >

        </div>
        <Margin mt={20} />
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {" "}
          <div>
            <div className="flex items-center m-0 p-0 gap-[10px]">
              <div className="bg-[#0F62FE] p-[15px]" style={{ borderRadius: "100px" }}>
                <img src={userIcon} />
              </div>
              <div className="section m-0 p-0">
                <h2 style={{ fontFamily: 'IBM Plex Mono' }}
                  className="text-[18px] m-0 font-[900]"
                > {beneficiaryDetails?.name}</h2>
                <h4
                  style={{ fontFamily: 'IBM Plex Mono' }}
                  className="text-[17px] font-[400] m-0 mt-1"
                >{formatCPFCNPJ(String(beneficiaryDetails?.document_number))}</h4>
              </div>
              <div className="section m-0 p-0">
                {beneficiaryDetails?.status &&
                  renderStatus[beneficiaryDetails?.status]}
              </div>
            </div>
            <Margin mt={5} />
          </div>
          {beneficiaryDetails?.status === "waiting_approval" && userProfile?.beneficiary_approver && (
            <div
              style={{
                display: "flex",
              }}
            >
              <div>
                <Button
                  size="medium"
                  onClick={() => {
                    setOpenReproveBeneficiaryModal(true);
                  }}
                  label={"Reprovar"}
                  kind="dangerPrimary"
                >
                </Button>
              </div>
              <Margin ml={10} />
              <div>
                <Button
                  kind="primary"
                  size="medium"
                  label={"Aprovar"}
                  onClick={() => setOpenApproveBeneficiaryModal(true)}>
                </Button>
              </div>
            </div>
          )}
        </div>
        <Margin mt={-2} />
        <div className="mx-auto my-3">
          <Tab width="1000px">
            <TabPane
              isActive={tab === "funds"}
              label="Fundos Associados"
              bordercolor="rgb(10, 74, 135)"
              onClick={() => handleTabChange("funds")}
              width="220px"
              position="center"
            >
              <div className="my-10 w-[100%]">
                <TableBasic
                  columnTitles={tableInfoFund}
                  pageSizes={[6, 2, 10]}
                  label="Fundos Associados"
                  rowData={dataFunds}
                  buttons={[
                    {
                      label: "",
                      icon: renewIcon,
                      dimension: "38",
                      width: "40px",
                      kind: "ghost",
                      size: "small",
                      onClick: () => "",
                      position: "right"
                    },
                    {
                      label: "Associar fundos",
                      kind: "primary",
                      onClick: () => setAddBeneficiaryFundModal(true),
                      position: "right",
                      disabled: beneficiaryDetails?.status === "waiting_approval",
                    }
                  ]}
                  search
                />
              </div>
            </TabPane>
            <TabPane
              isActive={tab === "banks"}
              bordercolor="rgb(10, 74, 135)"
              label="Contas Bancárias"
              onClick={() => handleTabChange("banks")}
              width="170px"
            >
              <div className="my-10 w-[100%]">
                <TableBasic
                  columnTitles={tableInfoAccount}
                  pageSizes={[6, 2, 10]}
                  label="Contas Bancárias"
                  rowData={dataAccountBeneficiary ? dataAccountBeneficiary : []}
                  buttons={[
                    {
                      label: "",
                      icon: renewIcon,
                      dimension: "38",
                      width: "40px",
                      kind: "ghost",
                      size: "small",
                      onClick: () => fetchBankAccountBeneficiary(),
                      position: "right"
                    },
                    {
                      label: "Adicionar conta bancária",
                      kind: "primary",
                      onClick: () => setEditModalBeneficiary(true),
                      position: "right",
                      disabled: beneficiaryDetails?.status === "waiting_approval",
                    }
                  ]}
                  search
                />
              </div>
            </TabPane>
            <TabPane
              isActive={tab === "pix"}
              label="Chaves PIX"
              bordercolor="rgb(10, 74, 135)"
              onClick={() => handleTabChange("pix")}
            >
              <div className="my-10 w-[100%]">
                <TableBasic
                  columnTitles={tablepixTypeAndKey}
                  pageSizes={[6, 2, 10]}
                  label="Chaves PIX"
                  rowData={dataPIXbeneficiary && dataPIXbeneficiary || []}
                  buttons={[
                    {
                      label: "",
                      icon: renewIcon,
                      dimension: "38",
                      width: "40px",
                      kind: "ghost",
                      size: "small",
                      onClick: () => fetchBeneficiary(),
                      position: "right"
                    },
                    {
                      label: "Adicionar chave PIX",
                      kind: "primary",
                      dimension: "38",
                      onClick: () => setaddkeyPixModal(true),
                      position: "right",
                      size: "small",
                      disabled: beneficiaryDetails?.status === "waiting_approval",
                    }
                  ]}
                  search
                />
              </div>
            </TabPane>
          </Tab>
        </div>
      </div >
      <DeleteAccountBeneficiary
        onClose={() => setDeleteModalAccountBeneficiary(false)}
        open={deleteAccountModalBeneficiary}
      />
      <DeleteModalKeyPix
        open={deleteModalKeyPix}
        onClose={() => setdeleteModalKeyPix(false)}
      />
      <ApproveBeneficiaryModal
        open={openApproveBeneficiaryModal}
        onClose={() => setOpenApproveBeneficiaryModal(false)}
      />
      <ReproveBeneficiaryModal
        open={openReproveBeneficiaryModal}
        onClose={() => setOpenReproveBeneficiaryModal(false)}
      />
      <AddBeneficiaryFundModal
        open={addBeneficiaryFundModal}
        onClose={() => setAddBeneficiaryFundModal(false)}
      />
      <ApproveFundBeneficiaryModal
        open={approveBeneficiaryFundModal}
        onClose={() => setApproveBeneficiaryFundModal(false)}
      />
      <ReproveFundBeneficiaryModal
        open={reproveBeneficiaryFundModal}
        onClose={() => setReproveBeneficiaryFundModal(false)}
      />
      <ApproveModalAccountBeneficiary
        open={appApproveAccountBeneficiaryModal}
        onClose={() => setAppApproveAccountBeneficiaryModal(false)}
      />
      <ReproveAccountModalBeneficiary
        open={appReproveAccountBeneficiaryModal}
        onClose={() => setappReproveAccountBeneficiaryModal(false)}
      />
      <NewAccountModal
        open={editModalBeneficiary}
        onClose={() => setEditModalBeneficiary(false)}
      />
      <AddModalPixkey
        open={addkeyPixModal}
        onClose={() => setaddkeyPixModal(false)}
      />
    </>
  );
};

export default BeneficiaryDetails;
