import {
    Button,
    Dropdown,
    InputFluid,
    InputSelect,
    Toogle
} from "prosperita-dumbo-react"
import Margin from "~/components/Margin"
import { ItypeRenderRegister } from "./Register"
import useFund from "~/hooks/useFund";
import { getFundsAPI } from "~/services/api/fund";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IFund, ITypeBanks } from "~/types";
import { formatCPFCNPJ } from "~/services/utils";
import useBanks from "~/hooks/useBanks";
import { getBanks } from "~/services/api/banks";
import useBeneficiary from "~/hooks/useBeneficiary";
import { useNavigate } from "react-router-dom";
import { IkeypixType, postCheckpixAPI } from "~/services/api/checkpix";
import useLoading from "~/hooks/useLoading";
import useNotification from "~/hooks/useToast";

type IBankAccountInfoPix = {
    account_number: string;
    account_number_digit: string;
    account_type: string;
    agency: string;
    bank: string;
    bank_name: string;
    document_number: string;
    key: string;
    key_type: string;
    message: string;
    name: string;
};

const dictError = {
    telefone: "O número de telefone inserido não é válido",
    cpf: "O número de CPF inserido não é válido",
    cnpj: "O número de CNPJ inserido não é válido",
    email: "O e-mail inserido não é válido",
    evp: "A chave aleatória inserida não é válida",
};

export const General = ({ formik }: ItypeRenderRegister) => {

    const navigate = useNavigate()
    const { handleChange, values, setFieldValue } = formik
    const { setLoading } = useLoading();
    const { setFunds, funds } = useFund();
    const { banks, setBanks } = useBanks();
    const [bankToggle, setbankToggle] = useState<boolean>(false)
    const { pixToggle, setpixToggle, setTab } = useBeneficiary()
    const [errorPIX, setErrorPIX] = useState<boolean>(false)
    const [pixData, setPIXData] = useState<IBankAccountInfoPix | null>();
    const [errorMessage, setErrorMessage] = useState<string>()

    const { setNotification, closeNotification } = useNotification()


    const fetchFunds = useCallback(async () => {
        try {
            const { data } = await getFundsAPI();
            setFunds(data.results);
        } catch (err: unknown) {
            console.log(err);
        }
    }, [setFunds]);


    const payload = {

        type: values.type,

        key: values.key
    }

    const fetchPIXverify = useCallback(async () => {
        setLoading(true)
        try {
            const response = await postCheckpixAPI(payload as IkeypixType);

            const data = response.data as IBankAccountInfoPix;

            setPIXData(data)

            setErrorPIX(false)

            setNotification({
                status: "success",
                message: "Chave PIX adicionada com sucesso.",
                title: "Sucesso",
                actived: true,
                onClose: () => closeNotification()
            });

        } catch (e) {
            console.log(e)

            setErrorPIX(true)

            setErrorMessage(values.type && dictError[values.type])

        } finally {

            setLoading(false)
        }
    }, [values, payload])


    const fetchBanks = useCallback(async () => {
        try {
            const { data } = await getBanks();
            setBanks(data.results);
        } catch (err: unknown) {
            console.log(err);
        }
    }, [setBanks]);

    const fundsOptions = useMemo(() => {
        return funds
            ?.filter((fund) => fund.raw_data.active === true)
            .map((fund: IFund) => ({
                value: String(fund.id),
                label: `${fund?.raw_data?.short_name?.trim()} - ${formatCPFCNPJ(fund?.raw_data?.document_number).trim()}`,
            }))
            .sort((a, b) =>
                a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
            );
    }, [funds]);

    const banksOptions = useMemo(() => {
        return banks
            ?.map((banks: ITypeBanks) => ({
                value: String(banks.id),
                label: String(`${banks.code} - ${banks.description}`),
            }))
            .sort((a, b) =>
                a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
            );
    }, [banks]);

    const setValuesFunds = (funds: unknown) => {
        setFieldValue && setFieldValue("fund", funds)
    };
    const optionsPIX = useMemo(() => {
        return [
            { label: "CNPJ", value: "cnpj" },
            { label: "CPF", value: "cpf" },
            { label: "Celular", value: "telefone" },
            { label: "Email", value: "email" },
            { label: "Aleatória", value: "evp" },
        ];
    }, []);

    const isValidButton = useCallback(() => {
        if (pixToggle) {
            return !!pixData
        }
        const hasTwoNames =
            values.name && values.name.trim().split(" ").length >= 2;
        const hasDocumentNumber =
            values.document_number.length === 11 ||
            values.document_number.length >= 11;
        return hasTwoNames && hasDocumentNumber && values.fund.length > 0;

    }, [values, pixToggle, pixData]);


    const handleToastPIX = () => {
        setNotification({
            status: "info",
            title: "Escolha sua Chave Pix",
            message: "Selecione o tipo de chave Pix e insira o valor correspondente.",
            actived: true,
            onClose: closeNotification
        });
    }
    const validateCPF = (cpf: string) => cpf.length === 11;
    const validateCNPJ = (cnpj: string) => cnpj.length === 14;
    const validateTelefone = (telefone: string) => /^(\+55)?[1-9][0-9]{10}$/.test(telefone);
    const validateEVP = (evp: string) => evp.length === 36;
    const validateEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const isPixKeyValid = () => {
        if (values.type === "cpf") return validateCPF(values.key);
        if (values.type === "cnpj") return validateCNPJ(values.key);
        if (values.type === "telefone") return validateTelefone(values.key);
        if (values.type === "evp") return validateEVP(values.key);
        if (values.type === "email") return validateEmail(values.key);
        return false;
    };

    useEffect(() => {
        if (isPixKeyValid()) {
            fetchPIXverify()
        }
        fetchFunds();
        fetchBanks()
    }, [pixToggle, errorPIX, values.key, values.type]);


    return (
        <div className="flex flex-col w-[100%]">
            <div className="flex w-[100%] flex-col gap-[1px] mt-10 bg-[#f4f4f4]">
                <div>
                    <InputSelect
                        kind="fluid"
                        options={fundsOptions}
                        size="medium"
                        placeholder="Selecione um ou mais fundos"
                        label="Selecione o(s) Fundo(s)"
                        noRadius
                        onChange={setValuesFunds}
                        isMulti
                    />
                </div>
                <div style={{ borderBottom: "1px solid #8d8d8d" }} className="flex gap-[2px]">
                    <InputFluid
                        type="text"
                        label="Nome/Razão Social"
                        placeholder="Digite o Nome/Razão Social"
                        noRadius
                        id={"name"}
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        borderNone
                    />
                    <InputFluid
                        type="text"
                        label="CPF/CNPJ"
                        placeholder="CPF/CNPJ"
                        noRadius
                        maxLength={14}
                        id={"document_number"}
                        name={"document_number"}
                        value={values.document_number}
                        onChange={handleChange}
                        borderNone
                    />
                </div>
            </div>
            <div>
                <Margin mt={30} />
                <p className="
             my-2
             p-0 text-[12px] 
             leading-[16px] 
             tracking-[0.32px] 
             text-[#525252]">Adicionar uma conta bancária</p>
                <Toogle
                    size="default"
                    onChange={(e) => {
                        setbankToggle(e.target.checked)
                    }}
                />
            </div>
            {bankToggle &&
                <div className="flex w-[100%] flex-col gap-[1px] mt-10 bg-[#f4f4f4]">
                    <div>
                        <Dropdown
                            kind="fluid"
                            options={banksOptions}
                            size="medium"
                            label="Selecione o Banco"
                            placeholder="Selecione a instituição"
                            name="banking_name"
                            onChange={handleChange}
                            noRadius
                        />
                    </div>
                    <div style={{ borderBottom: "1px solid #8d8d8d" }} className="flex gap-[2px]">
                        <InputFluid
                            type="text"
                            label="Agência"
                            placeholder="0000"
                            name="banking_agency"
                            value={values.banking_agency}
                            onChange={handleChange}
                            maxLength={6}
                            noRadius
                            borderNone
                        />
                        <InputFluid
                            type="text"
                            label="Conta (Com dígito)"
                            placeholder="00000000"
                            name="banking_account"
                            value={values.banking_account}
                            onChange={handleChange}
                            maxLength={15}
                            noRadius
                            borderNone
                        />
                    </div>
                </div>}
            <div>
                <Margin mt={30} />
                <p className="
             my-2
             p-0 text-[12px] 
             leading-[16px] 
             tracking-[0.32px] 
             text-[#525252]">Adicionar uma chave PIX</p>
                <Toogle
                    size="default"
                    checked={pixToggle}
                    onChange={(e) => {
                        setpixToggle(e.target.checked)
                        setFieldValue && setFieldValue("agency", "")
                        setFieldValue && setFieldValue("account_number", "")
                        setFieldValue && setFieldValue("key", "")
                        setFieldValue && setFieldValue("type", "")
                        setPIXData(undefined)
                    }}
                />
            </div>
            {pixToggle &&
                <div className="flex w-[100%] flex-col gap-[1px] mt-10 bg-[#f4f4f4]">
                    <div onClick={() => !pixData && handleToastPIX()} style={{ borderBottom: "1px solid #8d8d8d" }} className="mb-[0px]">
                        <InputFluid
                            type="text"
                            label="Banco"
                            placeholder="instituição"
                            name="bank"
                            readOnly
                            onChange={handleChange}
                            noRadius
                            value={pixData?.bank_name}
                            borderNone
                        />
                    </div>
                    <div onClick={() => !pixData && handleToastPIX()} style={{ borderBottom: "1px solid #8d8d8d" }} className="flex gap-[2px] mt-[0px]">
                        <InputFluid
                            type="text"
                            label="Agência"
                            placeholder="0000"
                            name="agency"
                            readOnly
                            value={values.agency || pixData?.agency}
                            onChange={handleChange}
                            noRadius
                            borderNone
                        />
                        <InputFluid
                            type="text"
                            label="Conta (Com dígito)"
                            placeholder="00000000"
                            name="account_number"
                            readOnly
                            value={values.account_number || pixData?.account_number}
                            onChange={handleChange}
                            noRadius
                            borderNone
                        />
                    </div>
                    <div style={{ borderBottom: "1px solid #8d8d8d" }} className="flex gap-[2px]">
                        <Dropdown
                            kind="fluid"
                            options={optionsPIX}
                            size="medium"
                            label="Tipo de chave PIX"
                            placeholder="Selecione o tipo"
                            noRadius
                            name="type"
                            onChange={handleChange}
                            borderNone
                        />
                        <InputFluid
                            type="text"
                            label="Chave"
                            placeholder="Digite a chave PIX"
                            noRadius
                            value={values.key}
                            name="key"
                            onChange={handleChange}
                            borderNone
                            error={errorPIX}
                            message={errorMessage}
                        />
                    </div>
                </div>
            }
            <Margin mt={60} />
            <div className="flex w-[100%] justify-between">
                <Button
                    label={"Cancelar"}
                    kind="ghost"
                    size="large"
                    onClick={() => navigate("/app/beneficiaries")}
                ></Button>
                <Button
                    label={"Avançar"}
                    kind="primary"
                    size="large"
                    disabled={!isValidButton()}
                    onClick={() => setTab("address")}
                ></Button>
            </div>
            <Margin mt={100} />
        </div>
    )
}
