import Margin from "~/components/Margin";
import { useCallback, useEffect } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import useUser from "~/hooks/useUser";

import {
  deleteBeneficiaryBankAccount,
  getBeneficiariesBankAccount,
  getBeneficiaryAPI,
} from "~/services/api/beneficiaries";

import { useParams } from "react-router-dom";
import { getBanks } from "~/services/api/banks";
import useBanks from "~/hooks/useBanks";
import { Modal } from "prosperita-dumbo-react";
import useNotification from "~/hooks/useToast";

const DeleteAccountBeneficiary = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { banks, setBanks } = useBanks();
  const {
    setBeneficiaryDetails,
    beneficiaryDetails,
    setDeleteModalAccountBeneficiary,
    beneficiaryAccounts,
    accountBeneficiary,
    setAccountBeneficiary
  } = useBeneficiary();
  banks;

  const { setNotification, closeNotification } = useNotification()

  const { id } = useParams();
  const { userProfile } = useUser();

  const fetchBeneficiary = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getBeneficiaryAPI(Number(id));
      setBeneficiaryDetails(data.data);

      setLoading(false);
    } catch {
      console.log("erro ao fazer requisição");
    }
  }, [setBeneficiaryDetails]);

  const fetchBanks = useCallback(async () => {
    try {
      const { data } = await getBanks();
      setBanks(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, []);

  // const BanksFilter = banks.reduce(
  //   (acc, items_) => {
  //     if (acc) {
  //       acc[items_.id] = items_.description;
  //     }
  //     return acc;
  //   },
  //   {} as { [key: string]: string }
  // );

  // const UpdateBeneficiary = (IDaccount: number) => {
  //   if (beneficiaryDetails) {
  //     const updatedBankAccounts: BankAccount[] = (
  //       beneficiaryDetails?.bank_account || []
  //     )
  //       .flatMap((account) => (Array.isArray(account) ? account : [account]))
  //       .filter((account) => account.id !== IDaccount);
  //     fetchDeleteAccount(updatedBankAccounts);
  //   }
  // };

  const account = beneficiaryAccounts?.find((item) => item)

  const fetchBankAccountBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiariesBankAccount(Number(id))
      setAccountBeneficiary(data.results)
    } catch (err) {
      console.warn(err)
    }

  }, [accountBeneficiary, beneficiaryAccounts])

  const fetchDeleteAccount = useCallback(
    async () => {
      setLoading(true);
      try {
        await deleteBeneficiaryBankAccount(Number(account?.id))
        fetchBeneficiary();
        fetchBankAccountBeneficiary()
        setNotification({
          status: "success",
          message: "A conta bancária foi removida com sucesso.",
          title: "Conta bancária removida",
          actived: true,
          zindex: "20",
          onClose: () => closeNotification()
        });

        setDeleteModalAccountBeneficiary(false);
        setLoading(false);
        console.warn("Requisição Realizada");
      } catch {
        setNotification({
          status: "error",
          message: "Não foi possível remover a conta bancária no momento. Por favor, tente novamente mais tarde ou entre em contato com o administrador do sistema.",
          title: "Falha ao remover conta bancária",
          actived: true,
          zindex: "20",
          onClose: () => closeNotification()
        });

        setLoading(false);
        console.warn("erro ao deletar");
      }
    },
    [fetchBankAccountBeneficiary, beneficiaryDetails, userProfile, id, beneficiaryAccounts]
  );


  useEffect(() => {
    fetchBanks();
  }, []);


  return (
    <Modal
      label="Remover conta bancária"

      open={open}
      onClose={onClose}

      buttons={
        [
          {
            size: "large",
            dimension: "75",
            kind: "ghost",
            label: "Cancelar",
            onClick: () => {
              onClose();

            }
          },
          {
            size: "large",
            dimension: "75",
            kind: "dangerPrimary",
            label: "Sim, remover",
            onClick: () => fetchDeleteAccount()
          }
        ]
      }
    >
      <div className="w-[100%] mx-auto text-center">
        <Margin mt={20} />
        <h3 style={{ textAlign: "center" }}>
          Deseja realmente remover a conta bancária abaixo ?
        </h3>
        <Margin mt={30} />
        <h4>
          <span style={{ fontWeight: "bold" }}>Banco: </span>{" "}
          {account?.bank}
        </h4>
        <h4>
          {" "}
          <span style={{ fontWeight: "bold" }}>Agencia: </span>{" "}
          {account?.agency}
        </h4>
        <h4>
          {" "}
          <span style={{ fontWeight: "bold" }}>Conta: </span>{" "}
          {account?.account_number}-{" "}
          {account?.account_number_digit}
        </h4>
      </div>
      <Margin mb={100} />
    </Modal >
  );
};

export default DeleteAccountBeneficiary;
