import constate from "constate";
import { useState } from "react";

type NotificationProps = {
    zindex?: string;
    top?: string;
    right?: string;
    onClose?: () => void;
    noRadius?: boolean;
    status: "error" | "success" | "warning" | "info";
    actived: boolean;
    title: string;
    message: string;
};

const [NotificationProvider, useNotification] = constate(() => {

    const [notification, setNotification] = useState<NotificationProps>({
        status: "error",
        message: "",
        title: "",
        actived: false,
        zindex: "0",
        right: "-800px",
    });

    const closeNotification = () => {
        setNotification((prev) => ({
          ...prev,
          actived: false,
        }));
      };

    return {
        setNotification,
        notification,
        closeNotification
    };
});

export { NotificationProvider };

export default useNotification;
