import Margin from "~/components/Margin";
import { useCallback, useMemo } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import {
  getBeneficiaryFundWIDAPI,
  IBodyPatchBeneficiary,
  patchBeneficiaryAPI,
  patchBeneficiaryFundAPI,
} from "~/services/api/beneficiaries";
import { formatCPFCNPJ } from "~/services/utils";
import { useParams } from "react-router-dom";
import { Modal } from "prosperita-dumbo-react"
import useNotification from "~/hooks/useToast";

// const clientTypeDisplay: { [key: string]: string } = {
//   client: "Cliente",
//   internal: "Atendente",
//   area_manager: "Gerente de àrea",
//   tool_manager: "Gerente de ferramenta",
// };

const ApproveFundBeneficiaryModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();

  const {
    beneficiaryFundDetails,
    beneficiaryDetails,
    setApproveBeneficiaryFundModal,
    setFundsBeneficiaryRender,
  } = useBeneficiary();

  const { id } = useParams();

  const { setNotification, closeNotification } = useNotification()

  const payload: IBodyPatchBeneficiary = useMemo(() => {
    return {
      raw_data: {
        status: beneficiaryDetails?.status,
        pending_funds_approvations: false,
      },
    };
  }, [beneficiaryDetails]);

  const fetchPatchBeneficiary = useCallback(
    async (id: number) => {
      try {
        await patchBeneficiaryAPI(id, payload);
      } catch (err: unknown) {
        console.error(err);
      }
    },
    [payload]
  );

  const fetchFundsBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiaryFundWIDAPI(Number(id));
      setFundsBeneficiaryRender(data.results.reverse());

      if (
        !data.results?.some(
          (fundBeneficiary) =>
            fundBeneficiary.raw_data.status === "waiting_approval"
        )
      ) {
        beneficiaryDetails &&
          fetchPatchBeneficiary(Number(beneficiaryDetails.id));
      }
    } catch {
      console.warn("erro");
    }
  }, [
    id,
    setFundsBeneficiaryRender,
    beneficiaryDetails,
    fetchPatchBeneficiary,
  ]);

  const fetchApproveFundBeneficiary = useCallback(
    async (id: number) => {
      setLoading(true);
      try {
        await patchBeneficiaryFundAPI(id, {
          raw_data: {
            status: "approved",
          },
        });
        await fetchFundsBeneficiary();
        setApproveBeneficiaryFundModal(false);
        setLoading(false);
        setNotification({
          status: "success",
          title: "Fundo Aprovado com Sucesso",
          message: "O fundo foi aprovado com sucesso.",
          actived: true,
          zindex: "20",
          onClose: closeNotification
        });

      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        setNotification({
          status: "error",
          title: "Não foi possível Aprovar Fundo",
          message: "Houve um erro ao tentar aprovar o fundo.",
          actived: true,
          zindex: "20",
          onClose: closeNotification
        });

      }
    },
    [setLoading, fetchFundsBeneficiary, setApproveBeneficiaryFundModal]
  );

  return (
    <Modal
      radius
      label="Aprovar associação de fundo"
      open={open}
      height="450px"
      width="550px"
      onClose={onClose}
      buttons={[

        {
          label: "Cancelar",
          kind: "secondary",
          onClick: () => onClose && onClose()

        },
        {
          label: "Sim, aprovar",
          kind: "primary",
          onClick: async () =>
            beneficiaryFundDetails &&
            fetchApproveFundBeneficiary(Number(beneficiaryFundDetails.id))

        }
      ]}
    >
      <Margin mt={20} />
      <div className="w-[96%] mx-auto text-center">
        <h3 className="text-center">
          Deseja aprovar a associação deste fundo?
        </h3>
        <Margin mb={10} />
        <h4 style={{ fontWeight: "bold" }}>
          {"Nome Abreviado: "}
          <span className="text-[22px]" style={{ fontWeight: "normal" }}>
            {beneficiaryFundDetails?.fund.raw_data.short_name}
          </span>
        </h4>
        <h4 style={{ fontWeight: "bold" }}>
          {"CNPJ: "}
          <span className="text-[22px]" style={{ fontWeight: "normal" }}>
            {beneficiaryFundDetails?.fund.raw_data.document_number &&
              formatCPFCNPJ(
                beneficiaryFundDetails?.fund.raw_data.document_number
              )}
          </span>
        </h4>
        <h4 style={{ fontWeight: "bold" }}>
          {"Nome Completo: "}
          <span className="text-[22px]" style={{ fontWeight: "normal" }}>
            {beneficiaryFundDetails?.fund.name}
          </span>
        </h4>
      </div>
      <Margin mb={10} />
    </Modal >
  );
};

export default ApproveFundBeneficiaryModal;
